import { useContext, useEffect, useRef, useState } from "react";
import Loader from "../../Assets/Components/Loader";
import axios from "axios";
import { AlertContext } from "../../Assets/Components/Alerter";
import { Card, CardContent, Button, TextField, Avatar, Grid, Typography, Container, IconButton, Divider, CircularProgress, FormControl, InputLabel, Select, MenuItem, Alert, useMediaQuery } from '@mui/material';

import { PhotoCamera } from '@mui/icons-material';
import { getGMTOffset } from "../../Assets/Utils";
import { Countries } from "../../Assets/countries";
import SaveIcon from '@mui/icons-material/Save';

export default function Account() {
    const [isLoading, setisLoading] = useState(true)
    const [isAvatarUpdating, setisAvatarUpdating] = useState(false)
    const [isUpdating, setisUpdating] = useState(false)
    const { setAlert } = useContext(AlertContext)
    const [userData, setUserData] = useState()
    const [avatar, setAvatar] = useState()
    const pictureInputRef = useRef()
    const [message, setMessage] = useState("");
    const isMobile = useMediaQuery('(max-width:1024px)');


    const [errors, seterrors] = useState({
        firstName: false,
        lastName: false,
        email: false,
        username: false,
        password: false,
        newPassword: false
    })


    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_API + ("/getUserData")).then(response => {
            setUserData(response.data.data)
        }).catch(err => {
            setAlert(err.response.data.data, false)
        }).finally(() => {
            setisLoading(false)
        })
    }, [])

    function saveDetails() {
        setisUpdating(true)
        axios.post(process.env.REACT_APP_BACKEND_API + "/setUserData", { userData: { ...userData, avatar: undefined } }).then((response) => {
            localStorage.setItem("user", JSON.stringify(response.data.data))
            setMessage("")
            seterrors({})
            setAlert("Profile updated !")
        }).catch((err) => {
            const msg = err.response.data.data
            setMessage(msg)


            if (msg.includes("first_name")) {
                return seterrors({ firstName: true })
            }
            if (msg.includes("last_name")) {
                return seterrors({ lastName: true })
            }
            if (msg.includes("email")) {
                return seterrors({ email: true })
            }
            if (msg.includes("username")) {
                return seterrors({ username: true })
            }
            if (msg.includes("Current password")) {
                return seterrors({ password: true })
            }
            if (msg.includes("New password")) {
                return seterrors({ newPassword: true })
            }
            if (msg.includes("already") && msg.includes("Email")) {
                return seterrors({ email: true })
            }
            if (msg.includes("already") && msg.includes("Username")) {
                return seterrors({ username: true })
            }
        }).finally(() => {
            setisUpdating(false)
        })
    }



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };

    const handleAvatarChange = async (event) => {
        const file = event.target.files[0]

        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                setAvatar(e.target.result)
            }
            reader.readAsDataURL(file)


            setisAvatarUpdating(true)

            const formData = new FormData()
            formData.append('avatars', file)

            axios.post(process.env.REACT_APP_BACKEND_API + '/setUserAvatar', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }).then((response) => {
                localStorage.setItem("user", JSON.stringify(response.data.data))
                setAlert("Picture updated !")
            }).catch(() => {
                setAlert("Failed to upload picture !", false)
            }).finally(() => {
                setisAvatarUpdating(false)
            })
        }
    };



    return (
        <Loader isLoading={isLoading}>
            {!isLoading &&
                <div className="container">
                    <Typography variant="h4" gutterBottom>
                        Account
                    </Typography>

                    <Grid container spacing={4}>
                        <Grid item xs={12} md={4}>
                            <div className="card-container" style={{ textAlign: "center" }}>
                                <Avatar
                                    alt={userData.first_name}
                                    src={(avatar ? avatar : (userData.avatar ? userData.avatar : userData.first_name))}
                                    sx={{ fontSize:isMobile ? "4rem" : "3rem",width: 120, height: 120, margin: 'auto', mb: 2 }}
                                />
                                <div className="text primary xlarge" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{userData.first_name} {userData.last_name}</div>
                                <div className="text secondary large mt-medium">{Countries[userData.country]?.toUpperCase()}</div>
                                <div className="text secondary large mt-small">{getGMTOffset()}</div>


                                <input
                                    ref={pictureInputRef}
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    type="file"
                                    onChange={handleAvatarChange}
                                />
                                <Button
                                    variant="contained"
                                    className="button primary medium"
                                    startIcon={<PhotoCamera style={isMobile ? { fontSize: "3rem" } : {}}/>}
                                    onClick={() => {
                                        pictureInputRef.current.click()
                                    }}
                                    sx={{ mt: 3 }}
                                    fullWidth
                                    disabled={isAvatarUpdating}
                                >
                                    Upload Picture
                                </Button>
                            </div>
                        </Grid>


                        <Grid item xs={12} md={8}>
                            <div className="card-container">
                                <div className="text primary xlarge">Profile</div>
                                <div className="text secondary large">The information can be edited</div>

                                <Divider className="divider secondary small" sx={{ marginBottom: "40px !important" }} />


                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="First Name"
                                            fullWidth
                                            name="first_name"
                                            value={userData.first_name}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            className="input primary"
                                            error={errors.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Last Name"
                                            fullWidth
                                            name="last_name"
                                            value={userData.last_name}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            className="input primary"
                                            error={errors.lastName}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Email"
                                            fullWidth
                                            name="email"
                                            value={userData.email}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            className="input primary"
                                            error={errors.email}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Username"
                                            fullWidth
                                            name="username"
                                            value={userData.username}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            className="input primary"
                                            error={errors.username}
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={6}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel>Country</InputLabel>
                                            <Select
                                                value={userData.country}
                                                name="country"
                                                onChange={handleInputChange}
                                                label="Country"
                                            >
                                                {Object.keys(Countries).map((countryCode) => (
                                                    <MenuItem key={countryCode} value={countryCode}>
                                                        {Countries[countryCode]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Divider className="divider secondary small" />


                                <Grid container spacing={2} className="mb-medium">
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Current Password"
                                            fullWidth
                                            name="password"
                                            value={userData.password}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            className="input primary"
                                            error={errors.password}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="New Password"
                                            fullWidth
                                            name="newPassword"
                                            value={userData.newPassword}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            className="input primary"
                                            error={errors.newPassword}
                                        />
                                    </Grid>
                                </Grid>



                                {message && message !== "ok" && (
                                    <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                                        {message}
                                    </Alert>
                                )}

                                <Button
                                    variant="outlined"
                                    className="button primary medium"
                                    onClick={saveDetails}
                                    disabled={isUpdating}
                                    startIcon={!isUpdating ? <SaveIcon style={isMobile ? { fontSize: "3rem" } : {}}/> : <CircularProgress size={!isMobile ? 25 : 50} color="inherit" />}
                                >
                                    {!isUpdating ? "Save Details" : "Saving..."}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }
        </Loader>
    );

}