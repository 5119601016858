import { Avatar, Box, Card, CardContent, Container, Grid, IconButton, Typography, useMediaQuery } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { LuWheat } from "react-icons/lu"
import { GiWheat } from "react-icons/gi"
import { GiPeas } from "react-icons/gi"
import { FaCow, FaPlateWheat } from "react-icons/fa6"
import { GiCow } from 'react-icons/gi'
import { PiCowFill, PiCowLight } from "react-icons/pi"
import { WiRainMix, WiRain, WiThunderstorm, WiDaySunny, WiCloudy, WiSnow, WiFog, WiCloud } from 'react-icons/wi'
import { BsCloudSun } from 'react-icons/bs'
import PendingIcon from '@mui/icons-material/Pending';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TaskIcon from '@mui/icons-material/Task';
import { useEffect, useState } from "react"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BackgroundSlider from "react-background-slider"
import React from 'react';

export const cropIcons = {
    "Wheat": <LuWheat style={{ color: '#F4A261', fontSize: "3rem" }} />,
    "Barley": <GiWheat style={{ color: '#1adfc7', fontSize: "3rem" }} />,
    'Field peas': <GiPeas style={{ color: '#264653', fontSize: "3rem" }} />,
    "Lupins": <FaPlateWheat style={{ color: '#E76F51', fontSize: "3rem" }} />
}

export const breedIcons = {
    "Holstein": <GiCow style={{ color: '#6bd4ff', fontSize: "3rem" }} />,
    "Jersey": <FaCow style={{ color: '#2A9D8F', fontSize: "3rem" }} />,
    "Guernsey": <PiCowFill style={{ color: '#E9C46A', fontSize: "3rem" }} />,
    "Ayrshire": <PiCowLight style={{ color: '#F4A261', fontSize: "3rem" }} />
}



export function IconText({ icon, text }) {
    return (
        <Box display="flex" alignItems="center" className="icon-text">
            <div className="icon primary">{icon}</div>
            <div className="text primary center large">{text}</div>
        </Box>
    )
}





export function CardIconItem({ label, labelStyle = {}, valueStyle = {}, value, icon, backgroundColor, cardStyle, darkMode = null }) {
    return (
        <Card
            variant="outlined"
            sx={{
                borderRadius: 2,
                boxShadow: `0px 0px 5px 1px ${(darkMode != null && cardStyle?.backgroundColor) ? cardStyle.backgroundColor : ((darkMode ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)"))}`,
                backgroundColor: darkMode != null ? (darkMode ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)") : backgroundColor,
                ...cardStyle
            }}
            className="card-icon"
        >
            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div className={"icon center xlarge" + (darkMode ? " primary" : " primary2")}>{icon}</div>
                <div className={"text center xlarge title" + (darkMode ? " primary" : " primary2")} style={labelStyle}>
                    {label}
                </div>
                <div className={"text center xlarge sub-title" + (darkMode ? " primary" : " primary2")} style={{ fontSize: 32, fontWeight: "bold", marginTop: 10, ...valueStyle }}>
                    {value}
                </div>
            </CardContent>
        </Card>
    )
}





export function Slogan() {
    const navigate = useNavigate()
    return (
        <div className="slogan" style={{ display: "flex", alignItems: "center" }}>
            <IconButton
                onClick={() => {
                    navigate("/")
                }}
            >
                <Avatar alt="Logo" src={require("../img/logo.png")} />
            </IconButton>
            <div className="text primary2 bold xlarge title">
                Decision Support System for Sustainable Farm Management and Resource Optimization
            </div>
        </div>
    )
}


export function IconTitle({ title, icon, style, imgStyle, titleStyle }) {
    return (
        <div className='icon-bg' style={{ height: "100vh", ...style }}>
            <div className='icon-bg-content'>
                <img style={{ width: "70%", ...imgStyle }} src={icon}></img>
                <div className='icon-bg-msg' style={{ ...titleStyle }}>{title}</div>
            </div>
        </div>
    )
}



export function EmptyPage() {
    return (
        <IconTitle title={"Sorry, the page you're looking for doesn't exist !"} icon={require("../img/404.gif")} />
    )
}











export function getWeatherIcon(description) {
    const descriptionLower = description.toLowerCase()

    const weatherColors = {
        lightRain: '#1E88E5',
        moderateRain: '#1976D2',
        heavyRain: '#0D47A1',
        thunderstorm: '#D32F2F',
        sunny: '#FF9800',
        fewClouds: '#616161',
        brokenClouds: '#9E9E9E',
        scatteredClouds: '#FFC107',
        overcastClouds: '#757575',
        snow: '#B0BEC5',
        mist: '#757575',
        fog: '#90A4AE',
        showerRain: '#42A5F5',
    }

    if (descriptionLower.includes("rain")) {
        if (descriptionLower.includes("light")) {
            return <WiRainMix color={weatherColors.lightRain} />
        } else if (descriptionLower.includes("moderate")) {
            return <WiRain color={weatherColors.moderateRain} />
        } else if (descriptionLower.includes("shower")) {
            return <WiRain color={weatherColors.showerRain} />
        } else if (descriptionLower.includes("heavy")) {
            return <WiRain color={weatherColors.heavyRain} />
        } else {
            return <WiThunderstorm color={weatherColors.thunderstorm} />
        }
    } else if (descriptionLower.includes("clear") || descriptionLower.includes("sky")) {
        return <WiDaySunny color={weatherColors.sunny} />
    } else if (descriptionLower.includes("cloud")) {
        if (descriptionLower.includes("few")) {
            return <WiCloudy color={weatherColors.fewClouds} />
        } else if (descriptionLower.includes("broken")) {
            return <BsCloudSun color={weatherColors.brokenClouds} />
        } else if (descriptionLower.includes("scattered")) {
            return <BsCloudSun color={weatherColors.scatteredClouds} />
        } else if (descriptionLower.includes("overcast")) {
            return <WiCloud color={weatherColors.overcastClouds} />
        } else {
            return <WiCloud />
        }
    } else if (descriptionLower.includes("snow")) {
        return <WiSnow color={weatherColors.snow} />
    } else if (descriptionLower.includes("mist")) {
        return <WiFog color={weatherColors.mist} />
    } else if (descriptionLower.includes("fog")) {
        return <WiFog color={weatherColors.fog} />
    } else {
        return <WiDaySunny />
    }
}

export function getWeatherBackground(description) {
    const descriptionLower = description.toLowerCase();

    const weatherBackgrounds = {
        lightRain: 'lightRain.jpg',
        moderateRain: 'moderateRain.jpg',
        heavyRain: 'heavyRain.jpg',
        thunderstorm: 'thunderstorm.jpg',
        sunny: 'sunny.jpg',
        fewClouds: 'fewClouds.jpg',
        brokenClouds: 'brokenClouds.jpg',
        scatteredClouds: 'scatteredClouds.jpg',
        overcastClouds: 'overcastClouds.jpg',
        snow: 'snow.jpg',
        mist: 'mist.jpg',
        fog: 'fog.jpg',
        showerRain: 'showerRain.jpg',
    };

    if (descriptionLower.includes("rain")) {
        if (descriptionLower.includes("light")) {
            return weatherBackgrounds.lightRain;
        } else if (descriptionLower.includes("moderate")) {
            return weatherBackgrounds.moderateRain;
        } else if (descriptionLower.includes("shower")) {
            return weatherBackgrounds.showerRain;
        } else if (descriptionLower.includes("heavy")) {
            return weatherBackgrounds.heavyRain;
        } else {
            return weatherBackgrounds.thunderstorm;
        }
    } else if (descriptionLower.includes("clear") || descriptionLower.includes("sky")) {
        return weatherBackgrounds.sunny;
    } else if (descriptionLower.includes("cloud")) {
        if (descriptionLower.includes("few")) {
            return weatherBackgrounds.fewClouds;
        } else if (descriptionLower.includes("broken")) {
            return weatherBackgrounds.brokenClouds;
        } else if (descriptionLower.includes("scattered")) {
            return weatherBackgrounds.scatteredClouds;
        } else if (descriptionLower.includes("overcast")) {
            return weatherBackgrounds.overcastClouds;
        } else {
            return weatherBackgrounds.brokenClouds;
        }
    } else if (descriptionLower.includes("snow")) {
        return weatherBackgrounds.snow;
    } else if (descriptionLower.includes("mist")) {
        return weatherBackgrounds.mist;
    } else if (descriptionLower.includes("fog")) {
        return weatherBackgrounds.fog;
    } else {
        return weatherBackgrounds.sunny;
    }
}

export const getStatusIcon = (status, isMobile = false) => {
    switch (status) {
        case 'Pending':
            return <PendingIcon sx={{ fontSize: isMobile ? "4rem" : "2rem" }} />
        case 'In Progress':
            return <PendingActionsIcon sx={{ fontSize: isMobile ? "4rem" :"2rem" }} />
        case 'Completed':
            return <TaskIcon sx={{ fontSize: isMobile ? "4rem" :"2rem" }} />
        default:
            return <TaskIcon sx={{ fontSize: isMobile ? "4rem" :"2rem" }} />
    }
}

export const getStatusColor = (status) => {
    switch (status) {
        case 'Pending':
            return '#FFA726'
        case 'In Progress':
            return '#42A5F5'
        case 'Completed':
            return '#66BB6A'
        default:
            return '#BDBDBD'
    }
}

export const getPriorityColor = (priority) => {
    switch (priority) {
        case 'Low':
            return '#4CAF50'
        case 'Medium':
            return '#FFC107'
        case 'High':
            return '#F44336'
        default:
            return '#9E9E9E'
    }
}






export function ImageCard({ name, image }) {
    const [isHovered, setIsHovered] = useState(false);

    const cardStyles = {
        position: 'relative',
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '60vh',
        width: '50vh',
        borderRadius: '15px',
        overflow: 'hidden',
        transition: '0.5s',
        display: 'flex',
        alignItems: 'flex-end',
        margin: "10px",
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        boxShadow: isHovered ? '0 4px 20px rgba(0, 0, 0, 0.5)' : 'none',
        cursor: "default"
    };

    const overlayStyles = {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        background: isHovered ? 'linear-gradient(to top, #308f32b8, rgba(0, 0, 0, 0))' : 'linear-gradient(to top, #308f326e, rgba(0, 0, 0, 0))',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: "0.5s",
    };

    return (
        <Card
            style={cardStyles}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div style={overlayStyles}>
                <div className="text primary xxlarge" style={{ color: 'white', textAlign: 'center' }}>
                    {name}
                </div>
            </div>
        </Card>
    );
}




export function ClockCard({ darkMode = false }) {
    const [time, setTime] = useState(new Date());
    const isMobile = useMediaQuery('(max-width:1024px)');


    useEffect(() => {
        const intervalId = setInterval(() => setTime(new Date()), 1000);
        return () => clearInterval(intervalId);
    }, [])


    const formatDate = (date) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    }

    return (
        <CardIconItem
            label={formatDate(time)}
            value={time.toLocaleTimeString()}
            labelStyle={isMobile ? { fontSize: "2.5rem" } : {}}
            valueStyle={isMobile ? { fontSize: "3rem" } : {}}
            icon={<AccessTimeIcon style={{ fontSize: isMobile ? "7rem" : "4rem" }} />}
            darkMode={darkMode} />
    )
}






export const WallpaperSlideshow = () => {
    const wallpapers = [
        require("../img/wallpaper/bg1.jpg"),
        require("../img/wallpaper/bg2.jpg"),
        require("../img/wallpaper/bg3.jpg"),
        require("../img/wallpaper/bg4.jpg"),
    ];

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const [shuffledWallpapers, setShuffledWallpapers] = useState([]);

    useEffect(() => {
        setShuffledWallpapers(shuffleArray([...wallpapers]));
    }, []);

    return (
        <BackgroundSlider
            images={shuffledWallpapers}
            duration={5}
            transition={1}
        />
    );
};






export function LeftIconCard({ icon, title, text, cardStyle, titleStyle, textStyle }) {
    return (
        <div className="card-container" style={{ ...cardStyle, padding: "30px" }}>
            <Grid container style={{ alignItems: "center" }}>
                <Grid item xs={3}>
                    <div className="icon primary xlarge">{icon}</div>
                </Grid>
                <Grid item xs={9}>
                    <div style={{ textAlign: "left" }}>
                        <div className="text primary2 xlarge bold title" style={{ ...titleStyle }}>{title}</div>
                        <div className="text primary2 large mt-small sub-title" style={{ ...textStyle }}>{text}</div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}







export function FancyBackground({ image, label, title, style }) {
    return (
        <div className="bg-section" style={{ position: "relative", ...style }}>
            <div style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0, backgroundColor: "rgba(0,0,0,0.4)" }}></div>

            <img className="bg-img" src={image} style={{ width: "100%", height: "40vh", objectFit: "cover" }} alt="background" />

            <div style={{ position: "absolute", top: "30%", color: "#fff", textAlign: "center", width: "100%" }}>
                <div className="text primary2 bold bg-title" style={{ fontSize: "3rem", margin: 0, marginBottom: "10px" }}>{title}</div>
                <span className="bg-sub-title" style={{ fontSize: "1.5rem", backgroundColor: "rgba(0,0,0,0.6)", padding: "0.2rem 0.5rem", borderRadius: "5px" }}>{label}</span>
            </div>




            <img src={require("../img/bg-layer.png")}
                style={{ position: "absolute", bottom: 0, left: 0, right: 0, height: "20%", width: "100%" }}
                alt="background-layer" />
        </div>
    );
}
