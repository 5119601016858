import { useEffect, useState } from "react";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Countries } from "../Assets/countries";
import { breeds, cropTypes, statisticsModel } from "../Assets/shared/InputModels";
import { getRandomRGBAColor } from "../Assets/Utils";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { PiFarmFill } from "react-icons/pi";
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import { Grid, Button, Divider, useMediaQuery } from "@mui/material";
import { CardIconItem, ImageCard } from "../Assets/Components/General";
import axios from "axios";
import Loader from "../Assets/Components/Loader";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";
import AssignmentIcon from '@mui/icons-material/Assignment';
import GrassIcon from '@mui/icons-material/Grass';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import NatureIcon from '@mui/icons-material/Nature';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import CloudIcon from '@mui/icons-material/Cloud';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import GroupsIcon from '@mui/icons-material/Groups';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);







export default function Home() {
    const [isLoading, setisLoading] = useState(true)
    const [statistics, setstatistics] = useState(statisticsModel())
    const [activeSlide, setActiveSlide] = useState(0); // Track the active slide

    const navigate = useNavigate()

    const isMobile = useMediaQuery('(max-width:1024px)');


    const slides = [
        { src: 'farm.jpg', title: 'Optimize Farm Management', subtitle: 'Streamline your daily tasks with smart tools.' },
        { src: 'forage.jpg', title: 'Boost Forage Productivity', subtitle: 'Optimize forage growth with data-driven insights.' },
        { src: 'crop.jpg', title: 'Manage Crop', subtitle: 'Leverage real-time data to maximize your crop yield.' },
        { src: 'herd.jpg', title: 'Ensure Herd Well-being', subtitle: 'Keep your livestock healthy with advanced monitoring tools.' },
        { src: 'meteo.jpg', title: 'Accurate Weather Forecasts', subtitle: 'Plan your farming activities with precise meteorological data.' },
    ];



    useEffect(() => {
        window.scroll(0, 0)
        axios.get(process.env.REACT_APP_BACKEND_API + "/getStatistics").then((response) => {
            setstatistics(response.data.data)
        }).catch(() => {
        }).finally(() => {
            setisLoading(false)
        })
    }, [])


    const handleSlideChange = (index) => {
        setActiveSlide(index); // Update the active slide index
    };

    const FeaturesCardStyle = {
        backgroundColor: "#fff",
        cursor: "default",
        transition: "0.5s",
        "&:hover": {
            backgroundColor: "#00ab5c3b",
            transform: "scale(1.1)",
            transition: "0.2s"
        }
    }

    const statisticsCardStyle = {
        boxShadow: "none",
        backgroundColor: "transparent",
        cursor: "default",
    }


    const carouselLabelStyles = (isActive) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: isActive ? 'translate(-50%, -50%) scale(1)' : 'translate(-50%, -50%) scale(0.8)',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center',
        opacity: isActive ? 1 : 0,
        transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
    });

    const fadeInTextStyles = (isActive) => ({
        opacity: isActive ? 1 : 0,
        transform: isActive ? 'translateY(0)' : 'translateY(20px)',
        transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
    });


    return (
        <Loader isLoading={false}>
            <div className="container" style={{ marginTop: "80px", padding: 0 }}>
                <Carousel
                    autoPlay
                    infiniteLoop
                    showThumbs={false}
                    showStatus={false}
                    interval={3000}
                    onChange={handleSlideChange}
                >
                    {slides.map((slide, index) => (
                        <div key={index} style={{ position: 'relative' }}>
                            <img src={require(`../Assets/img/${slide.src}`)} alt={`Wallpaper ${index + 1}`} />
                            <div className="carousel-card" style={carouselLabelStyles(activeSlide === index)}>
                                <div className="text primary2 bold title" style={{ ...fadeInTextStyles(activeSlide === index), fontSize: "3rem" }}>{slide.title}</div>
                                <div className="text primary2 sub-title" style={{ ...fadeInTextStyles(activeSlide === index), fontSize: "1.5rem" }}>{slide.subtitle}</div>
                            </div>
                        </div>
                    ))}
                </Carousel>



                <div className="home-section2" style={{ padding: "5vh", background: "url(" + require("../Assets/img/bg-layers/paper.jpg") + ")", backgroundSize: "cover" }}>
                    <div className="text primary2 center section-title" style={{ fontSize: "2.5rem" }}>Focus on Your Passion, Not the Paperwork.</div>

                    <Button
                        variant="contained"
                        className="button primary2 success large"
                        sx={{
                            padding: 2,
                            borderRadius: "30px !important",
                            display: "flex",
                            mr: "auto",
                            ml: "auto",
                            mt: 2,
                            mb: 2
                        }}
                        onClick={() => {
                            navigate("/signup")
                        }}
                    >
                        Get Started
                    </Button>


                    <div className="text primary2 large center sub-title">✓ No Credit Card Required    ✓ Full Access to All Features</div>
                </div>



                <div className="card-container home-section3">
                    <div className='text primary xxlarge center mb-medium section-title'>Know more. Grow more. Sell more.</div>


                    <Grid container spacing={4} sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={isMobile ? 6 : 4}>
                            <CardIconItem label={"Optimize Farm Efficiency"}
                                value={"Collaborate with your team to streamline farm operations. Track everything in real-time, from field activities to equipment usage, improving overall farm efficiency."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", color: "#000" }}
                                icon={<AssignmentIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 4}>
                            <CardIconItem label={"Increase Crop Yields"}
                                value={"Leverage data insights and weather forecasts to optimize planting, irrigation, and harvesting schedules, resulting in higher and more reliable crop yields."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", color: "#000" }}
                                icon={<GrassIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 4}>
                            <CardIconItem label={"Boost Soil Health"}
                                value={"Use smart recommendations to maintain fertile soil and enhance crop growth."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", color: "#000" }}
                                icon={<NatureIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                    </Grid>


                    <Grid container spacing={4} mt={2} sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={isMobile ? 6 : 4}>
                            <CardIconItem label={"Maximize Resource Efficiency"}
                                value={"Optimize water usage, energy consumption, and labor allocation using predictive models. Reduce waste and improve resource allocation across all farm activities."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", fontSize: 18, color: "#000" }}
                                icon={<LocalGasStationIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>

                        <Grid item xs={isMobile ? 6 : 4}>
                            <CardIconItem label={"Adapt to Changing Conditions"}
                                value={"Stay ahead of environmental changes with precise weather and climate data. Adapt farm operations in real-time to changing conditions and minimize risks."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", fontSize: 18, color: "#000" }}
                                icon={<CloudIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 4}>
                            <CardIconItem label={"User-Friendly and Easy to Implement"}
                                value={"No complex setup or technical skills required. The system is designed to be intuitive and easy to use."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", fontSize: 18, color: "#000" }}
                                icon={<ThumbsUpDownIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} mt={2} sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={isMobile ? 6 : 4}>
                            <CardIconItem label={"Turn Data into Action"}
                                value={"Leverage comprehensive farm data to make informed decisions. Analyze trends, optimize resource use, and ensure compliance with regulatory requirements."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", fontSize: 18, color: "#000" }}
                                icon={<DataUsageIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 4}>
                            <CardIconItem label={"Simplify Record-Keeping"}
                                value={"Eliminate the need for spreadsheets by managing farm records in one centralized system. Access all data securely from any device for convenience and efficiency."}
                                labelStyle={{ fontWeight: "bold", color: "#000" }}
                                valueStyle={{ fontWeight: "normal", fontSize: 18, color: "#000" }}
                                icon={<DescriptionIcon sx={{ color: "#4d8c20 !important" }} />}
                                cardStyle={FeaturesCardStyle} />
                        </Grid>
                    </Grid>
                </div>


                <div className="home-section4" style={{ padding: "5vh", background: "url(" + require("../Assets/img/bg-layers/tomato.jpg") + ")", backgroundSize: "cover" }}>
                    <div className='text primary2 xxlarge center section-title'>Our Growth Story</div>

                    <Grid container spacing={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={2}>
                            <CardIconItem label={"Total Managed Farms"} value={statistics.countFarms}
                                icon={<PiFarmFill />}
                                cardStyle={statisticsCardStyle} />
                        </Grid>


                        <Grid item xs={2}>
                            <CardIconItem label={"Total Users"} value={statistics.countUsers}
                                icon={<SupervisedUserCircleIcon />}
                                cardStyle={statisticsCardStyle} />
                        </Grid>



                        {Object.keys(statistics.countNationalities).find(key => statistics.countNationalities[key]) ?
                            <Grid item xs={3}>
                                <CardIconItem label={"Nationalities"} value={Object.keys(statistics.countNationalities).length}
                                    icon={<Grid item xs={12} sx={{ height: "25vh" }}>
                                        <NationalityChart data={statistics} />
                                    </Grid>}
                                    cardStyle={statisticsCardStyle} />
                            </Grid>
                            :
                            ""
                        }

                        <Grid item xs={2}>
                            <CardIconItem label={"Total Teams"} value={statistics.countTeams}
                                icon={<GroupsIcon />}
                                cardStyle={statisticsCardStyle} />
                        </Grid>


                        <Grid item xs={2}>
                            <CardIconItem label={"Total Simulations"} value={statistics.countUsers}
                                icon={<VideoSettingsIcon />}
                                cardStyle={statisticsCardStyle} />
                        </Grid>


                    </Grid>
                </div>






                <div className='card-container home-section5'>
                    <div className='text primary xxlarge center'>Supported Crops</div>
                    <Grid item xs={12}>
                        {isMobile ?
                            <>
                                <div className="mt-medium" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    {cropTypes.slice(0, cropTypes.length / 2).map((crop) => (
                                        <ImageCard
                                            key={crop}
                                            name={crop}
                                            image={require(`../Assets/img/crops/${crop.replace(/ /g, '_')}.jpg`)}
                                        />
                                    ))}
                                </div>
                                <div className="mt-medium" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    {cropTypes.slice(cropTypes.length / 2).map((crop) => (
                                        <ImageCard
                                            key={crop}
                                            name={crop}
                                            image={require(`../Assets/img/crops/${crop.replace(/ /g, '_')}.jpg`)}
                                        />
                                    ))}
                                </div>
                            </>
                            :
                            <div className="mt-medium" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                {cropTypes.map((crop) => (
                                    <ImageCard
                                        key={crop}
                                        name={crop}
                                        image={require(`../Assets/img/crops/${crop.replace(/ /g, '_')}.jpg`)}
                                    />
                                ))}
                            </div>
                        }

                    </Grid>



                    <div className='text primary xxlarge center mt-large'>Supported Herd Breeds</div>
                    <Grid item xs={12}>
                        {isMobile ?
                            <><div className="mt-medium" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                {breeds.slice(0, breeds.length / 2).map((breed) => (
                                    <ImageCard
                                        key={breed}
                                        name={breed}
                                        image={require(`../Assets/img/herd/${breed.replace(/ /g, '_')}.jpg`)}
                                    />
                                ))}
                            </div>
                                <div className="mt-medium" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    {breeds.slice(breeds.length / 2).map((breed) => (
                                        <ImageCard
                                            key={breed}
                                            name={breed}
                                            image={require(`../Assets/img/herd/${breed.replace(/ /g, '_')}.jpg`)}
                                        />
                                    ))}
                                </div>
                            </>
                            :
                            <div className="mt-medium" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                {breeds.map((breed) => (
                                    <ImageCard
                                        key={breed}
                                        name={breed}
                                        image={require(`../Assets/img/herd/${breed.replace(/ /g, '_')}.jpg`)}
                                    />
                                ))}
                            </div>
                        }
                    </Grid>
                </div>



                <div className="home-section6" style={{ padding: "5vh", background: "url(" + require("../Assets/img/bg-layers/plant-growth.jpg") + ")",backgroundSize:"cover" }}>
                    <MiscellaneousServicesIcon sx={{ display: "flex", marginLeft: "auto", marginRight: "auto", color: "#fff", fontSize: "6rem" }} />
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Divider className="divider primary2 small mr-medium" style={{ width: "20%" }} />
                        <div className='text primary2 xxlarge center section-title'>All Plans Include</div>
                        <Divider className="divider primary2 small ml-medium" style={{ width: "20%" }} />
                    </div>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "2rem" }}>
                        <ul>
                            <li className="text primary2 bold mb-medium">Customizable dashboards for data visualization</li>
                            <li className="text primary2 bold mb-medium">Advanced weather prediction models</li>
                            <li className="text primary2 bold mb-medium">Collaboration tools for team management</li>
                            <li className="text primary2 bold mb-medium">Coordinate and mange your farm schedule</li>
                            <li className="text primary2 bold mb-medium">Data analytics for performance optimization</li>
                        </ul>

                        <ul >
                            <li className="text primary2 bold mb-medium">Real-time farm activity tracking</li>
                            <li className="text primary2 bold mb-medium">Encrypted data transmission and storage</li>
                            <li className="text primary2 bold mb-medium">24/7 customer support for technical assistance</li>
                            <li className="text primary2 bold mb-medium">Upload and securely store images & files</li>
                            <li className="text primary2 bold mb-medium">Regular updates and improvements released</li>
                        </ul>
                    </div>

                </div>



            </div>
        </Loader>
    );
}




const NationalityChart = ({ data }) => {
    const nationalityData = {
        labels: Object.keys(data.countNationalities).map((CountryCode => Countries[CountryCode])),
        datasets: [
            {
                data: Object.values(data.countNationalities),
                backgroundColor: Object.keys(data.countNationalities).map(a => getRandomRGBAColor())
            },
        ],
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                display: false
            },
            title: {
                display: false,
                text: 'Nationality Distribution',
            },
        },
    }

    return <Pie data={nationalityData} options={options} />
}