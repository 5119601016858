import React from 'react';
import { useContext, useEffect, useState } from "react";
import { WeatherForcastModel, weatherModel } from "../../Assets/shared/InputModels";
import axios from "axios";
import { fetchWeatherData, getUserPosition, timestampToHour } from "../../Assets/Utils";
import { AlertContext } from "../../Assets/Components/Alerter";
import { Typography, Box, Grid, IconButton, Slide, useMediaQuery } from '@mui/material';
import { WiWindy } from 'react-icons/wi';
import { WiRaindrop, WiHumidity, WiSolarEclipse } from 'react-icons/wi';
import { CardIconItem, ClockCard, getWeatherBackground, getWeatherIcon } from '../../Assets/Components/General';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import { Countries } from "../../Assets/countries";
import Loader from "../../Assets/Components/Loader";





export default function Weather() {
    const [todayWeatherData, settodayWeatherData] = useState(weatherModel())
    const [todayWeatherForcast, settodayWeatherForcast] = useState(WeatherForcastModel())
    const [weatherForcastData, setWeatherForcastData] = useState(WeatherForcastModel())
    const [background, setBackground] = useState('');
    const { setAlert } = useContext(AlertContext)
    const [open, setOpen] = useState(false);
    const [selectedForecast, setSelectedForecast] = useState()
    const [isLoading, setisLoading] = useState(true)
    const isMobile = useMediaQuery('(max-width:1024px)');



    useEffect(() => {
        init()
    }, [])


    async function init() {
        getUserPosition(setAlert, async (userPos) => {

            try {
                const response = await axios.get(process.env.REACT_APP_BACKEND_API + "/fetchForcastWeatherData", {
                    params: { lat: userPos.lat, lon: userPos.lon, startDate: new Date(), daysToForecast: 1 }
                })

                settodayWeatherForcast(response.data.data)
            } catch (error) {
                settodayWeatherForcast([])
            }


            try {
                const response = await axios.get(process.env.REACT_APP_BACKEND_API + "/fetchForcastWeatherData", {
                    params: { lat: userPos.lat, lon: userPos.lon, startDate: new Date(), daysToForecast: 5 }
                })


                setWeatherForcastData(response.data.data)
            } catch (error) {
                setWeatherForcastData([])
            }


            const todayWeatherData = await fetchWeatherData(userPos.lat, userPos.lon)



            setBackground(require(`../../Assets/img/weather/${getWeatherBackground(todayWeatherData.description)}`))
            settodayWeatherData(todayWeatherData)
            setisLoading(false)
        })
    }




    const handleClickOpen = (forecast) => {
        setSelectedForecast(forecast)
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <Loader isLoading={isLoading}>
            <div style={{
                position: 'relative',
                height: '100%',
                overflow: 'hidden',
            }}>


                <div style={{
                    background: `url(${background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    height: '100%',
                    width: '100%',
                    opacity: 0.9,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                }} />




                <div style={{ display: isMobile ? "inherit" : 'flex', flexDirection: 'row', position: 'relative', zIndex: 2 }}>
                    <Box sx={{ flex: 2, p: 2 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div className='card-container mt-medium' style={{ backgroundColor: "rgba(0,0,0,0.5)", marginLeft: "inherit" }}>
                                <div className='text primary2 xxlarge' style={isMobile ? { fontSize: "5rem" } : {}}>{Countries[todayWeatherData.country]}</div>
                                <div className='text primary2' style={{ fontSize: isMobile ? "9rem" : "5rem" }}>{todayWeatherData.temperature.toFixed(0)}°C</div>
                                <div className='text primary2 xlarge' style={isMobile ? { fontSize: "3.5rem" } : {}}>{todayWeatherData.description}</div>
                            </div>
                            <ClockCard darkMode={false} />
                            {/*<IconButton sx={{ fontSize: "15rem" }}>{getWeatherIcon(todayWeatherData.description)}</IconButton>*/}
                        </div>




                        <div className='card-container mt-medium' style={{ backgroundColor: "rgba(255,255,255,0.4)" }}>
                            <div className='text primary xlarge mb-medium' style={isMobile ? { fontSize: "3rem" } : {}}>24-Hour Forecast</div>
                            <Grid container spacing={2}>
                                {todayWeatherForcast.hourlyForecastList.map((forecast, idx) => (
                                    <Grid item xs={isMobile ? 6 : 3} key={idx}>
                                        <div className='card-container' style={{ textAlign: "center", backgroundColor: "rgba(255,255,255,0.6)" }} >
                                            <div className='text secondary large' style={isMobile ? { fontSize: "3rem" } : {}}>{timestampToHour(forecast.timestamp, true)}</div>
                                            <IconButton sx={{ fontSize: isMobile ? "10rem" : "4rem" }}>{getWeatherIcon(forecast.description)}</IconButton>
                                            <div className='text secondary large' style={isMobile ? { fontSize: "3rem" } : {}}>{forecast.description}</div>
                                            <div className='text primary xlarge bold mt-medium' style={isMobile ? { fontSize: "3rem" } : {}}>{forecast.temperature.toFixed(0)}°C</div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>


                        <div className='mt-medium' >
                            <Grid container spacing={2}>
                                <Grid item xs={isMobile ? 6 : 3}>
                                    <CardIconItem labelStyle={isMobile ? { fontSize: "4rem" } : {}} valueStyle={isMobile ? { fontSize: "4rem" } : {}} label={"Wind Speed"} value={todayWeatherData.windSpeed.toFixed(0) + " km/h"} icon={<WiWindy style={{ fontSize: isMobile ? "10rem" : "5rem" }} />} darkMode={false} />
                                </Grid>

                                <Grid item xs={isMobile ? 6 : 3}>
                                    <CardIconItem labelStyle={isMobile ? { fontSize: "4rem" } : {}} valueStyle={isMobile ? { fontSize: "4rem" } : {}} label={"Humidity"} value={todayWeatherData.humidity.toFixed(0) + " %"} icon={<WiHumidity style={{ fontSize: isMobile ? "10rem" : "5rem" }} />} darkMode={false} />
                                </Grid>



                                <Grid item xs={isMobile ? 6 : 3}>
                                    <CardIconItem labelStyle={isMobile ? { fontSize: "4rem" } : {}} valueStyle={isMobile ? { fontSize: "4rem" } : {}} label={"Precipitation"} value={todayWeatherData.precipitation.toFixed(2) + " mm"} icon={<WiRaindrop style={{ fontSize: isMobile ? "10rem" : "5rem" }} />} darkMode={false} />
                                </Grid>


                                <Grid item xs={isMobile ? 6 : 3}>
                                    <CardIconItem labelStyle={isMobile ? { fontSize: "4rem" } : {}} valueStyle={isMobile ? { fontSize: "4rem" } : {}} label={"Radiation"} value={todayWeatherData.radiation.toFixed(2) + " MJ/m²"} icon={<WiSolarEclipse style={{ fontSize: isMobile ? "10rem" : "5rem" }} />} darkMode={false} />
                                </Grid>
                            </Grid>
                        </div>
                    </Box>



                    <div className='card-container mt-medium' style={{ marginBottom:isMobile ? "8rem" : "2rem",flex: 1, p: 2, overflowY: 'auto', backgroundColor: "rgba(255,255,255,0.4)" }}>
                        <div className='text primary xlarge mb-medium' style={isMobile ? { fontSize: "3rem" } : {}}>5-Day Forecast</div>
                        <Grid container spacing={2}>
                            {weatherForcastData.dailyForecastList.map((forecast, idx) => (
                                <>
                                    <Grid item xs={12} key={idx}>
                                        <div className='card-container' style={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: "rgba(255,255,255,0.6)" }}>
                                            <div className='text secondary large' style={isMobile ? { fontSize: "2.5rem" } : {}}>{timestampToHour(forecast.timestamp, true, false)}</div>
                                            <div style={{ textAlign: "center" }}>
                                                <IconButton sx={{ fontSize: isMobile ? "8rem" : "4rem" }}>{getWeatherIcon(forecast.description)}</IconButton>
                                                <div className='text secondary large' style={isMobile ? { fontSize: "2.5rem" } : {}}>{forecast.description}</div>
                                            </div>
                                            <div className='text primary large bold mt-small' style={isMobile ? { fontSize: "2rem" } : {}}>{forecast.minTemp.toFixed(0)}°C - {forecast.maxTemp.toFixed(0)}°C</div>

                                            <Button variant="contained" className='button primary small' style={{ position: "absolute", top: 0, right: 0, margin: 5, fontSize: isMobile ? "2rem" : "inherit" }} onClick={() => handleClickOpen(forecast)}>
                                                See More
                                            </Button>
                                        </div>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </div >


                    <Dialog
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxWidth: isMobile ? "100%" : "50%"
                            },
                        }}
                    >
                        <DialogTitle style={isMobile ? { fontSize: "3rem" } : {}}>Weather Details</DialogTitle>
                        <DialogContent >
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={isMobile ? 6 : 4}>
                                        <CardIconItem labelStyle={isMobile ? { fontSize: "3rem" } : {}} valueStyle={isMobile ? { fontSize: "4rem" } : {}} label={"Min Temperature"} value={selectedForecast?.minTemp.toFixed(0) + " °C"} icon={<ThermostatIcon style={{ fontSize: isMobile ? "5rem" : "3rem" }} />} backgroundColor={"#9E9E9E"} />
                                    </Grid>
                                    <Grid item xs={isMobile ? 6 : 4}>
                                        <CardIconItem labelStyle={isMobile ? { fontSize: "3rem" } : {}} valueStyle={isMobile ? { fontSize: "4rem" } : {}} label={"Max Temperature"} value={selectedForecast?.maxTemp.toFixed(0) + " °C"} icon={<ThermostatIcon style={{ fontSize: isMobile ? "5rem" : "3rem" }} />} backgroundColor={"#FF5722"} />
                                    </Grid>


                                    <Grid item xs={isMobile ? 6 : 4}>
                                        <CardIconItem labelStyle={isMobile ? { fontSize: "3rem" } : {}} valueStyle={isMobile ? { fontSize: "4rem" } : {}} label={"Average Wind Speed"} value={selectedForecast?.windSpeed.toFixed(0) + " km/h"} icon={<WiWindy style={{ fontSize: isMobile ? "5rem" : "3rem" }} />} backgroundColor={"#03A9F4"} />
                                    </Grid>

                                    <Grid item xs={isMobile ? 6 : 4}>
                                        <CardIconItem labelStyle={isMobile ? { fontSize: "3rem" } : {}} valueStyle={isMobile ? { fontSize: "4rem" } : {}} label={"Average Humidity"} value={selectedForecast?.humidity.toFixed(0) + " %"} icon={<WiHumidity style={{ fontSize: isMobile ? "5rem" : "3rem" }} />} backgroundColor={"#4CAF50"} />
                                    </Grid>



                                    <Grid item xs={isMobile ? 6 : 4}>
                                        <CardIconItem labelStyle={isMobile ? { fontSize: "3rem" } : {}} valueStyle={isMobile ? { fontSize: "4rem" } : {}} label={"Average Precipitation"} value={selectedForecast?.precipitation.toFixed(2) + " mm"} icon={<WiRaindrop style={{ fontSize: isMobile ? "5rem" : "3rem" }} />} backgroundColor={"#2196F3"} />
                                    </Grid>


                                    <Grid item xs={isMobile ? 6 : 4}>
                                        <CardIconItem labelStyle={isMobile ? { fontSize: "3rem" } : {}} valueStyle={isMobile ? { fontSize: "4rem" } : {}} label={"Average Radiation"} value={selectedForecast?.radiation.toFixed(2) + " MJ/m²"} icon={<WiSolarEclipse style={{ fontSize: isMobile ? "5rem" : "3rem" }} />} backgroundColor={"#FFC107"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} className='button secondary medium' style={isMobile ? { fontSize: "2.5rem" } : {}}>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div >
            </div>
        </Loader>
    )
}