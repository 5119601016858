import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserData, isAuthenticated } from "../Utils";
import { Avatar, Box, Button, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { IconText, Slogan } from "./General";
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeIcon from '@mui/icons-material/Home';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LoginIcon from '@mui/icons-material/Login';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorElUser, setAnchorElUser] = useState(null)
    const [anchorElMobileUser, setanchorElMobileUser] = useState(null)
    const [anchorElMobile, setanchorElMobile] = useState(null)
    const [isShown, setisShown] = useState(false)
    const userData = getUserData()


    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    }


    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }


    const handleOpenMobileMenu = (event) => {
        setanchorElMobile(event.currentTarget);
    }


    const handleCloseMobileMenu = () => {
        setanchorElMobile(null);
    }


    const handleOpenMobileUserMenu = (event) => {
        setanchorElMobileUser(event.currentTarget);
    }


    const handleCloseMobileUserMenu = () => {
        setanchorElMobileUser(null);
    }




    const handleNavigation = useCallback((path) => {
        if (location.pathname === path) return


        navigate(path);
    }, [location.pathname, navigate])


    useEffect(() => {
        if (location.pathname.includes("/user")) {
            setisShown(false)
        } else {
            setisShown(true)
        }
    }, [location.pathname])


    function UserNavItem({ anchor, handleCloseMenu, handleOpenMenu }) {
        return (
            <li style={{ marginLeft: "10px" }}>
                <Tooltip title={userData.first_name + " " + userData.last_name}>
                    <IconButton onClick={handleOpenMenu} sx={{ p: 0 }}>
                        <Avatar alt="Avatar" src={(userData.avatar ? userData.avatar : userData.first_name)} />
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchor)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={() => {
                        handleCloseMenu()
                        navigate("/user")
                    }}>
                        <IconText icon={<DashboardIcon style={{ marginRight: "10px" }} />} text={"Dashboard"} />
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleCloseMenu()
                        localStorage.clear()
                        navigate("/login")
                    }}>
                        <IconText icon={<LogoutIcon style={{ marginRight: "10px" }} />} text={"Logout"} />
                    </MenuItem>
                </Menu>
            </li>
        )
    }


    if (isShown)
        return (
            <div className="header-container">
                <div className="header">
                    <Slogan />

                    <nav className="header-navbar">
                        <ul className="nav">
                            <li
                                onClick={() => handleNavigation("/")}
                                className={location.pathname === "/" ? "nav-item active" : "nav-item"}
                            >
                                <a className="nav-link"><HomeIcon />Home</a>
                            </li>

                            <li
                                onClick={() => handleNavigation("/contact")}
                                className={location.pathname === "/contact" ? "nav-item active" : "nav-item"}
                            >
                                <a className="nav-link"><SupportAgentIcon />Contact</a>
                            </li>

                            {isAuthenticated() ?
                                <li style={{ marginLeft: "10px" }}>
                                    <Tooltip title={userData.first_name + " " + userData.last_name}>
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar alt="Avatar" src={(userData.avatar ? userData.avatar : userData.first_name)} />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        <MenuItem onClick={() => {
                                            handleCloseUserMenu()
                                            navigate("/user")
                                        }}>
                                            <IconText icon={<DashboardIcon style={{ marginRight: "10px" }} />} text={"Dashboard"} />
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            handleCloseUserMenu()
                                            localStorage.clear()
                                            navigate("/login")
                                        }}>
                                            <IconText icon={<LogoutIcon style={{ marginRight: "10px" }} />} text={"Logout"} />
                                        </MenuItem>
                                    </Menu>
                                </li>
                                :
                                <>
                                    <li
                                        onClick={() => handleNavigation("/login")}
                                    >
                                        <Button startIcon={<LoginIcon />} className={"button primary large bold ml-medium" + (location.pathname == "/login" ? " active" : "")}>Login</Button>
                                    </li>
                                </>
                            }

                        </ul>





                        <ul className="nav mobile-menu">
                            <li style={{ marginLeft: "10px" }}>
                                <IconButton onClick={handleOpenMobileMenu} sx={{ p: 0 }}>
                                    {Boolean(anchorElMobile) ? <CloseIcon /> : <MenuIcon />}
                                </IconButton>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElMobile}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElMobile)}
                                    onClose={handleCloseMobileMenu}
                                >
                                    <MenuItem onClick={() => {
                                        handleCloseMobileMenu()
                                        navigate("/")
                                    }}>
                                        <IconText icon={<HomeIcon style={{ marginRight: "10px" ,fontSize:"4rem"}} />} text={"Home"} />
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleCloseMobileMenu()
                                        navigate("/contact")
                                    }}>
                                        <IconText textStyle={{fontSize:"3rem !important"}} icon={<SupportAgentIcon style={{ marginRight: "10px",fontSize:"4rem" }} />} text={"Contact Us"} />
                                    </MenuItem>

                                    {!isAuthenticated() &&
                                        <MenuItem onClick={() => {
                                            handleCloseMobileMenu()
                                            navigate("/login")
                                        }}>
                                            <IconText textStyle={{fontSize:"3rem !important"}} icon={<LoginIcon style={{ marginRight: "10px" ,fontSize:"4rem"}} />} text={"Login"} />
                                        </MenuItem>}
                                </Menu>
                            </li>
                            {isAuthenticated() &&
                                <li style={{ marginLeft: "10px" }}>
                                    <Tooltip title={userData.first_name + " " + userData.last_name}>
                                        <IconButton onClick={handleOpenMobileUserMenu} sx={{ p: 0 }}>
                                            <Avatar alt="Avatar" src={(userData.avatar ? userData.avatar : userData.first_name)} />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElMobileUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElMobileUser)}
                                        onClose={handleCloseMobileUserMenu}
                                    >
                                        <MenuItem onClick={() => {
                                            handleCloseMobileUserMenu()
                                            navigate("/user")
                                        }}>
                                            <IconText icon={<DashboardIcon style={{ marginRight: "10px" }} />} text={"Dashboard"} />
                                        </MenuItem>
                                        <MenuItem onClick={() => {
                                            handleCloseMobileUserMenu()
                                            localStorage.clear()
                                            navigate("/login")
                                        }}>
                                            <IconText icon={<LogoutIcon style={{ marginRight: "10px" }} />} text={"Logout"} />
                                        </MenuItem>
                                    </Menu>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
            </div>
        );
}
