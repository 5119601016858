import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Select,
    MenuItem,
    Autocomplete,
    Paper,
    Grid,
    CircularProgress,
    Snackbar,
    Box,
    Avatar,
    Tooltip,
    IconButton,
    Chip,
    Divider,
    FormControl,
    InputLabel,
    Alert,
    useMediaQuery
} from '@mui/material'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parseISO } from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import axios from 'axios'; // For making API calls
import Loader from '../../Assets/Components/Loader';
import { IconTitle } from '../../Assets/Components/General';
import { getUserData } from '../../Assets/Utils';
import { AlertContext } from '../../Assets/Components/Alerter';
import { Add, Close, PriorityHigh, CalendarToday, PeopleAlt, Description, AssignmentTurnedIn } from '@mui/icons-material'
import AssignmentIcon from '@mui/icons-material/Assignment'
import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/Delete';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';



// Setup for date-fns localizer
const locales = {
    'en-US': require('date-fns/locale/en-US'),
};

const EventCalendar = () => {
    const [teams, setTeams] = useState([])
    const [events, setEvents] = useState([]);
    const [selectedTeamCategory, setSelectedTeamCategory] = useState(0)
    const [filteredTeams, setfilteredTeams] = useState([])
    const [selectedTeam, setSelectedTeam] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openViewDialog, setopenViewDialog] = useState(false);
    const [currentEvent, setCurrentEvent] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const { setAlert } = useContext(AlertContext)
    const [isSaving, setisSaving] = useState(false)
    const [isDeleting, setisDeleting] = useState(false)
    const userData = getUserData()


    const isMobile = useMediaQuery('(max-width:1024px)');



    const localizer = dateFnsLocalizer({
        format,
        parse: (date) => parseISO(date),
        startOfWeek: () => new Date(),
        getDay: (date) => date.getDay(),
        locales,
    });


    useEffect(() => {
        if (!teams.length) {
            return
        }

        let newFilteredTeams = []
        if (selectedTeamCategory == 0) {
            newFilteredTeams = teams
        } else if (selectedTeamCategory == 1) {
            newFilteredTeams = teams.filter(team => team.owner._id == userData._id)
        } else {
            newFilteredTeams = teams.filter(team => team.owner._id != userData._id)
        }

        setfilteredTeams(newFilteredTeams)
        setSelectedTeam(newFilteredTeams[0]?._id)

        if (!newFilteredTeams.length) {
            setEvents([])
        }
    }, [teams, selectedTeamCategory])

    useEffect(() => {
        fetchTeams()
    }, [])


    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/getUserTeams`)
            setTeams(response.data.data)
            if (response.data.data.length > 0) {
                setSelectedTeam(response.data.data[0]._id)
            } else {
                setIsLoading(false)
            }
        } catch (error) {
            setAlert(error, false)
        }
    }

    // Fetch events when the component mounts
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                setIsLoading(true)

                const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/getTeamEvents`, { teamId: selectedTeam });
                if (response.data && response.data.data) {

                    const formattedEvents = response.data.data.map(event => ({
                        ...event,
                        start: new Date(event.start),
                        end: new Date(event.end),
                    }))
                    setEvents(formattedEvents)
                } else {
                    throw new Error('Unexpected response structure');
                }
            } catch (error) {
                console.error("Error fetching events:", error.response ? error.response.data : error);
                setSnackbarMessage('Failed to load events');
                setSnackbarOpen(true);
            } finally {
                setIsLoading(false);
            }
        };


        if (selectedTeam) {
            fetchEvents();
        }
    }, [selectedTeam]);

    const handleSelect = ({ start, end }) => {
        setCurrentEvent({ start, end, title: '' });
        setOpenDialog(true);
    };

    const handleEventClick = (event) => {
        if (teams.find(team => team._id == selectedTeam).owner._id !== userData._id) {
            setCurrentEvent(event);
            setopenViewDialog(true);
            return
        }
        setCurrentEvent(event);
        setOpenDialog(true);
    };

    const handleDelete = async () => {
        if (currentEvent) {
            try {
                setisDeleting(true)
                await axios.post(process.env.REACT_APP_BACKEND_API + '/removeTeamEvent', { eventId: currentEvent._id }); // Use MongoDB ID
                setEvents(events.filter((event) => event._id !== currentEvent._id)); // Delete by MongoDB ID
                setSnackbarMessage('Event deleted successfully!');
                setSnackbarOpen(true);
                setOpenDialog(false);
                setisDeleting(false)
            } catch (error) {
                console.error("Error deleting event:", error);
                setSnackbarMessage('Failed to delete event');
                setSnackbarOpen(true);
            }
        }
    };

    const handleSave = async () => {
        const { start, end, title } = currentEvent;

        if (title && start && end) {
            try {
                setisSaving(true)
                if (events.some((e) => e._id === currentEvent._id)) {
                    // Update existing event
                    await axios.post(process.env.REACT_APP_BACKEND_API + '/editTeamEvent', {
                        eventId: currentEvent._id,
                        title,
                        start,
                        end,
                    });
                    setEvents(events.map((e) => (e._id === currentEvent._id ? { ...e, title, start, end } : e)));
                    setSnackbarMessage('Event updated successfully!');
                } else {
                    // Add new event
                    const response = await axios.post(process.env.REACT_APP_BACKEND_API + '/addTeamEvent', {
                        teamId: selectedTeam,
                        title,
                        start,
                        end,
                    });

                    const formattedEvents = [...events, response.data.data].map(event => ({
                        ...event,
                        start: new Date(event.start),
                        end: new Date(event.end),
                    }))
                    setEvents(formattedEvents)
                    setSnackbarMessage('Event added successfully!');
                }

                setSnackbarOpen(true);
                setOpenDialog(false);
                setisSaving(false)
            } catch (error) {
                console.error("Error saving event:", error);
                setSnackbarMessage('Failed to save event');
                setSnackbarOpen(true);
            }
        } else {
            setSnackbarMessage('Please fill all fields.');
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setopenViewDialog(false)
        setCurrentEvent(null);
    };

    return (
        <Loader isLoading={isLoading}>
            {!isLoading && (
                !teams.length ? (
                    <IconTitle
                        style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}
                        title={"You currently have no teams to manage events. Create or join a team to get started!"}
                        icon={require("../../Assets/img/task management.gif")}
                    />
                ) : (
                    <div className='container'>
                        <div className='text primary xxlarge'>Event Management</div>

                        <div className='card-container'>
                            <div className='text primary large'>Select Team Category</div>
                            <Select
                                value={selectedTeamCategory}
                                onChange={(e) => setSelectedTeamCategory(e.target.value)}
                                fullWidth
                            >
                                <MenuItem value={0}>
                                    All Teams
                                </MenuItem>
                                <MenuItem value={1}>
                                    Your Teams
                                </MenuItem>
                                <MenuItem value={2}>
                                    Joined Teams
                                </MenuItem>
                            </Select>

                            <Divider className='divider primary medium' />

                            <div className='text primary large'>Select Team</div>
                            <Select
                                value={selectedTeam}
                                onChange={(e) => setSelectedTeam(e.target.value)}
                                displayEmpty
                                fullWidth
                                disabled={!filteredTeams.length}
                            >
                                {filteredTeams.map((team) => (
                                    <MenuItem key={team._id} value={team._id}>
                                        {team.name}
                                    </MenuItem>
                                ))}
                            </Select>

                            <Divider className='divider primary medium' />

                            <Button
                                variant="contained"
                                className='button primary medium'
                                onClick={() => handleSelect({ start: new Date(), end: new Date() })}
                                disabled={!selectedTeam || teams.find(team => team._id === selectedTeam).owner._id !== userData._id}
                            >
                                <Add style={isMobile ? { fontSize: "3rem" } : {}} /> Add Event
                            </Button>
                        </div>

                        <div className='card-container mt-medium' style={{ height: "50vh" }}>
                            <Calendar
                                localizer={localizer}
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: '100%' }}
                                selectable={selectedTeam && !(teams.find(team => team._id === selectedTeam).owner._id !== userData._id)}
                                onSelectSlot={handleSelect}
                                onSelectEvent={handleEventClick}
                            />
                        </div>





                        <Dialog
                            open={openViewDialog}
                            onClose={handleDialogClose}
                            sx={{ '& .MuiDialog-paper': { width: isMobile ? "80%" : '40%', maxWidth: 'none' } }}>
                            <DialogTitle style={isMobile ? { fontSize: "3rem" } : {}}>
                                Event Details
                                <IconButton
                                    aria-label="close"
                                    onClick={handleDialogClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <Close style={isMobile ? { fontSize: "2rem" } : {}} />
                                </IconButton>
                            </DialogTitle>

                            {currentEvent ? <DialogContent dividers>
                                <Box display="flex" alignItems="center" mb={2}>
                                    <AssignmentIcon style={isMobile ? { fontSize: "3rem" } : {}} sx={{ marginRight: 1, color: 'action.active' }} />
                                    <div className='text primary bold medium'>{currentEvent.title}</div>
                                </Box>

                                <Box display="flex" alignItems="center" mb={2}>
                                    <CalendarToday style={isMobile ? { fontSize: "3rem" } : {}} sx={{ marginRight: 1, color: 'action.active' }} />
                                    <div className='text primary bold medium mr-small'>Start Date:</div>
                                    <div className='text secondary medium'>{format(new Date(currentEvent.start), "yyyy-MM-dd h:mm a")}</div>
                                </Box>

                                <Box display="flex" alignItems="center" mb={2}>
                                    <CalendarToday style={isMobile ? { fontSize: "3rem" } : {}} sx={{ marginRight: 1, color: 'action.active' }} />
                                    <div className='text primary bold medium mr-small'>End Date:</div>
                                    <div className='text secondary medium'>{format(new Date(currentEvent.end), "yyyy-MM-dd h:mm a")}</div>
                                </Box>

                                <Box display="flex" alignItems="center" mb={2}>
                                    <HourglassEmptyIcon style={isMobile ? { fontSize: "3rem" } : {}} sx={{ marginRight: 1, color: 'action.active' }} />
                                    <div className='text primary bold medium mr-small'>Status:</div>
                                    <Chip
                                        label={new Date() < currentEvent?.end ? "Ongoing" : "Expired"}
                                        sx={{ fontSize: isMobile ? "2.5rem" : "inherit", padding: isMobile ? "1.5rem" : "inherit", backgroundColor: (new Date() < currentEvent?.end ? "#4CAF50" : "#F44336"), color: 'white', fontWeight: 'bold' }}
                                    />
                                </Box>
                            </DialogContent>
                                :
                                ""
                            }

                            <DialogActions>
                                <Button
                                    onClick={handleDialogClose}
                                    className="button secondary medium"
                                    startIcon={<CancelIcon style={isMobile ? { fontSize: "2rem" } : {}} />}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>


                        <Dialog
                            open={openDialog}
                            onClose={handleDialogClose}
                            sx={{ '& .MuiDialog-paper': { width: isMobile ? "80%" : '40%', maxWidth: 'none' } }}>
                            <DialogTitle style={isMobile ? { fontSize: "3rem" } : {}}>
                                {currentEvent ? 'Edit Event' : 'Add Event'}
                                <IconButton
                                    aria-label="close"
                                    onClick={handleDialogClose}
                                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                                >
                                    <Close style={isMobile ? { fontSize: "2rem" } : {}} />
                                </IconButton>
                            </DialogTitle>


                            <DialogContent dividers>
                                <Box display="flex" alignItems="center" mb={2}>
                                    <AssignmentIcon style={isMobile ? { fontSize: "3rem" } : {}} sx={{ marginRight: 1, color: 'action.active' }} />
                                    <TextField
                                        label="Event Title"
                                        fullWidth
                                        defaultValue={currentEvent ? currentEvent.title : ''}
                                        onChange={(e) => {
                                            const title = e.target.value;
                                            setCurrentEvent((prev) => ({ ...prev, title }));
                                        }}

                                    />
                                </Box>
                                <Box display="flex" alignItems="center" mb={2}>
                                    <CalendarToday style={isMobile ? { fontSize: "3rem" } : {}} sx={{ marginRight: 1, color: 'action.active' }} />
                                    <TextField
                                        type="datetime-local"
                                        label="Start Date"
                                        fullWidth
                                        defaultValue={currentEvent ? format(new Date(currentEvent.start), "yyyy-MM-dd'T'HH:mm") : ''}
                                        onChange={(e) => {
                                            const start = new Date(e.target.value);
                                            setCurrentEvent((prev) => ({ ...prev, start }));
                                        }}

                                    />
                                </Box>
                                <Box display="flex" alignItems="center" mb={2}>
                                    <CalendarToday style={isMobile ? { fontSize: "3rem" } : {}} sx={{ marginRight: 1, color: 'action.active' }} />
                                    <TextField
                                        type="datetime-local"
                                        label="End Date"
                                        fullWidth
                                        defaultValue={currentEvent ? format(new Date(currentEvent.end), "yyyy-MM-dd'T'HH:mm") : ''}
                                        onChange={(e) => {
                                            const end = new Date(e.target.value);
                                            setCurrentEvent((prev) => ({ ...prev, end }));
                                        }}

                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>


                                <Button style={isMobile ? { fontSize: "2rem" } : {}} onClick={handleSave} color="primary" className="button primary medium" startIcon={<SaveIcon style={isMobile ? { fontSize: "2rem" } : {}} />} disabled={isSaving}>
                                    Save
                                </Button>
                                {currentEvent?._id && (
                                    <Button style={isMobile ? { fontSize: "2rem" } : {}} onClick={handleDelete} className='button error medium' startIcon={<DeleteIcon style={isMobile ? { fontSize: "2rem" } : {}} />} disabled={isDeleting}>
                                        Delete
                                    </Button>
                                )}
                                <Button style={isMobile ? { fontSize: "2rem" } : {}} onClick={handleDialogClose} className="button secondary medium" startIcon={<CancelIcon style={isMobile ? { fontSize: "2rem" } : {}} />}>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>


                        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                    </div>
                )
            )}
        </Loader>
    );
};

export default EventCalendar;
