import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, Grid, useMediaQuery } from '@mui/material';
import { CardIconItem } from './General';

// CSS styles for the clock
const styles = {
    clockContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f0f0f0',
        color: '#333',
        textAlign: 'center',
    },
    dateText: {
        fontSize: '1.2rem',
        marginBottom: '10px',
        opacity: 0.8,
        color: '#555',
    },
    timeCard: {
        margin: '0 5px',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        minWidth: '100px',
        display: 'inline-block',
    },
    timeText: {
        opacity: 1,
        transform: 'scale(1)',
        transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out',
    },
    fadeOut: {
        opacity: 0,
        transform: 'scale(0.6)',
    },
    fadeIn: {
        opacity: 1,
        transform: 'scale(1)',
    },
};

const Clock = () => {
    const [time, setTime] = useState({
        hours: '00',
        minutes: '00',
        seconds: '00',
        amPm: 'AM',
    });

    const [animate, setAnimate] = useState({
        hours: false,
        minutes: false,
        seconds: false,
        amPm: false,
    });
    const isMobile = useMediaQuery('(max-width:1024px)');


    useEffect(() => {
        let animationFrameId;

        const updateClock = () => {
            const now = new Date();
            const currentHours = now.getHours();
            const currentMinutes = now.getMinutes().toString().padStart(2, '0');
            const currentSeconds = now.getSeconds().toString().padStart(2, '0');
            const currentAmPm = currentHours >= 12 ? 'PM' : 'AM';

            const formattedHours = (currentHours % 12 || 12).toString().padStart(2, '0');

            // Check if any time value has changed to trigger animation
            const newAnimate = {
                hours: formattedHours !== time.hours,
                minutes: currentMinutes !== time.minutes,
                seconds: currentSeconds !== time.seconds,
                amPm: currentAmPm !== time.amPm,
            };

            setAnimate(newAnimate);

            // Update the time state after the animations trigger
            if (newAnimate.hours || newAnimate.minutes || newAnimate.seconds || newAnimate.amPm) {
                setTimeout(() => {
                    setTime({
                        hours: formattedHours,
                        minutes: currentMinutes,
                        seconds: currentSeconds,
                        amPm: currentAmPm,
                    });
                }, 200); // Match this to the animation duration
            }

            // Use requestAnimationFrame for smoother updates
            animationFrameId = requestAnimationFrame(updateClock);
        };

        const intervalId = setInterval(() => {
            updateClock();
        }, 1000);

        updateClock();

        return () => {
            clearInterval(intervalId);
            cancelAnimationFrame(animationFrameId);
        };
    }, [time]);

    const renderTimeCard = (label="",value, isAnimating) => (
        <Grid item>
            <CardIconItem label={label} value={value} valueStyle={{
                ...styles.timeText,
                ...(isAnimating ? styles.fadeOut : styles.fadeIn),
                fontSize:isMobile ? "6rem" : "3rem"
            }} labelStyle={{fontSize:isMobile ? "3rem" : "1.5rem"}} darkMode={false} />
        </Grid>
    );

    return (
        <div>
            <div className='text secondary center xxlarge mb-medium' style={isMobile ? {fontSize:"3rem"}: {}}>
                {new Date().toLocaleDateString(undefined, {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })}
            </div>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center',alignItems:"center" }}>
                {renderTimeCard("Hours",time.hours, animate.hours)}
                {renderTimeCard("Minutes",time.minutes, animate.minutes)}
                {renderTimeCard("Seconds",time.seconds, animate.seconds)}
                {renderTimeCard("Day/Night",time.amPm, animate.amPm)}
            </Grid>
        </div>
    );
};

export default Clock;
