import React from 'react';
import { DateCalendar } from '@mui/x-date-pickers';
import { useMediaQuery } from '@mui/material';

const Calendar = () => {
    const isMobile = useMediaQuery('(max-width:1024px)');

    return (
        <>
            <div className='text secondary center xxlarge' style={isMobile ? { fontSize: "3rem" } : {}}>Calendar</div>
            <DateCalendar sx={{
                width: "-webkit-fill-available !important",
                ".MuiPickersCalendarHeader-labelContainer": {
                    fontSize: isMobile ? "2rem" : "1.2rem",
                },
                '.MuiTypography-root': {
                    fontSize: isMobile ? "2.2rem" : "1.5rem"
                },
                '.MuiPickersDay-root': {
                    fontSize: isMobile ? "2rem" : "1rem",
                    margin: isMobile ? "1rem 0" : "inherit",
                },
                '.MuiButtonBase-root': {
                    fontSize: isMobile ? "3rem" : "1.5rem"
                },
                ".MuiSvgIcon-root": {
                    fontSize: isMobile ? "4rem !important" : "2rem"
                },
                '.MuiDayCalendar-header': {
                    gap: isMobile ? "2.3rem" : "1rem",
                },
                ".MuiDayCalendar-weekContainer": {
                    gap: isMobile ? "2.5rem" : "1.5rem",
                },
                ".MuiPickersYear-root button":{
                    fontSize: isMobile ? "2rem" : "2rem"
                }
            }} />
        </>
    );
};

export default Calendar;
