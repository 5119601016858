import { Alert, Snackbar } from "@mui/material"
import { createContext, useState } from "react"


export const AlertContext = createContext(null)



export default function AlerterComponent({ children }) {
    const [alert, setAlertState] = useState({
        title: "",
        success: true
    })
    const [isOpen,setisOpen]=useState(false)

    const setAlert = (title, success = true) => {
        setisOpen(true)
        setAlertState({
            title,
            success
        })
    }


    return (
        <AlertContext.Provider value={{ setAlert }}>
            <Snackbar open={isOpen} autoHideDuration={3000} onClose={() => {
                setisOpen(false)
            }}>
                <Alert onClose={() => {
                    setisOpen(false)
                }} severity={alert.success ? "success" : "error"} variant='filled' style={{ position: "fixed", top: "90%", left: "50%", transform: "translate(-50%, -50%)", fontSize: "20px", display: "flex", alignItems: "center" }}>
                    {alert.title}
                </Alert>
            </Snackbar>
            {children}
        </AlertContext.Provider>
    )
}