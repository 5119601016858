import { Avatar, Divider, Fab, Grid, IconButton, InputAdornment, Link, TextField, Tooltip, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ShieldIcon from '@mui/icons-material/Shield';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BarChartIcon from '@mui/icons-material/BarChart';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { LeftIconCard } from "./General";
import axios from "axios";
import { AlertContext } from "./Alerter";


export default function Footer() {
    const location = useLocation()
    const [isShown, setisShown] = useState(false)
    const [email, setEmail] = useState("")
    const { setAlert } = useContext(AlertContext)
    const [isSendingMail, setisSendingMail] = useState(false)

    const isMobile = useMediaQuery('(max-width:1024px)');


    useEffect(() => {

        if (location.pathname == "/" || location.pathname == "/login" || location.pathname == "/signup" || location.pathname == "/contact" || location.pathname == "/about") {
            setisShown(true)
        } else {
            setisShown(false)
        }
    }, [location.pathname])


    function sendEmail() {
        setisSendingMail(true)
        axios.post(process.env.REACT_APP_BACKEND_API + "/subscribeNewsletter", { email }).then(() => {
            setAlert("Thank you for subscribing to our newsletter!")
            setEmail("")
        }).catch((err) => {
            setAlert(err.response.data.data, false)
        }).finally(() => {
            setisSendingMail(false)
        })
    }



    if (isShown)
        return (
            <div className="footer" style={{ backgroundColor: "#333", padding: "20px" }}>
                <Grid className="cards-grid" container spacing={5} sx={{ justifyContent: "center", alignItems: "center" }}>
                    <Grid className="grid" item xs={isMobile ? 12 : 4}>
                        <LeftIconCard icon={<ShieldIcon sx={{ fontSize: "4rem !important", color: "#b0bc4a !important" }} />}
                            title={"Secure"}
                            text={"Safety measures in place every step of the way, with trustworthy service being our top priority."}
                            cardStyle={{ backgroundColor: "#45495e" }}
                            titleStyle={{ color: "#b0bc4a" }} />
                    </Grid>
                    <Grid className="grid" item xs={isMobile ? 12 : 4}>
                        <LeftIconCard icon={<SupportAgentIcon sx={{ color: "#b0bc4a !important" }} />}
                            title={"Reliable Support"}
                            text={"Our expert team is available 24/7 to assist you with any questions or technical challenges."}
                            cardStyle={{ backgroundColor: "#45495e" }}
                            titleStyle={{ color: "#b0bc4a" }} />
                    </Grid>
                    <Grid className="grid" item xs={isMobile ? 12 : 4}>
                        <LeftIconCard icon={<BarChartIcon sx={{ color: "#b0bc4a !important" }} />}
                            title={"Data-Driven Decisions"}
                            text={"Leverage actionable insights from your farm data to make smarter, faster decisions."}
                            cardStyle={{ backgroundColor: "#45495e" }}
                            titleStyle={{ color: "#b0bc4a" }} />
                    </Grid>
                </Grid>


                <Grid className="footer-section2" container spacing={5} sx={{ justifyContent: "center", mt: 1 }}>
                    <Grid item xs={isMobile ? 12 : 3}>
                        <div style={{ display: "inline-block", textAlign: "center" }}>
                            <div className="text primary2 xxlarge bold mb-small title">Our Story</div>
                            <Divider className="divider primary2 medium mb-medium" />
                        </div>

                        <div className="text primary2 medium sub-title">
                            Driven by a passion for agriculture, we created a platform to simplify farm management and boost productivity. Our mission is to empower farmers with smart tools, real-time insights, and sustainable solutions. We grow with our community, helping farms thrive today and into the future.
                        </div>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 3}>
                        <div style={{ display: "inline-block", textAlign: "center" }}>
                            <div className="text primary2 xxlarge bold mb-small title">Discover</div>
                            <Divider className="divider primary2 medium mb-medium" />
                        </div>

                        <div className="mb-small"><Link className="text primary2 large sub-title" href={"/"}>Home</Link></div>
                        <div className="mb-small"><Link className="text primary2 large sub-title" href={"/signup"}>Signup</Link></div>
                        <div className="mb-small"><Link className="text primary2 large sub-title" href={"/login"}>Login</Link></div>
                        <div className="mb-small"><Link className="text primary2 large sub-title" href={"/#:~:text=Know%20more.%20Grow%20more.%20Sell%20more."}>Services</Link></div>
                        <div className="mb-small"><Link className="text primary2 large sub-title" href={"/contact"}>Contact</Link></div>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 3}>
                        <div style={{ display: "inline-block", textAlign: "center" }}>
                            <div className="text primary2 xxlarge bold mb-small title">Newsletter</div>
                            <Divider className="divider primary2 medium mb-medium" />
                        </div>

                        <div className="text primary2 medium mb-medium sub-title">
                            Stay updated with the latest news, exclusive offers, and special announcements! Enter your email address below to subscribe to our newsletter and never miss out on important updates.
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", gap: '1rem' }}>
                            <TextField
                                sx={{
                                    backgroundColor: "#b0bc4a"
                                }}
                                variant="outlined"
                                className="input primary"
                                fullWidth
                                label="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />


                            <IconButton
                                className="button primary rounded"
                                onClick={sendEmail}
                                disabled={isSendingMail}
                            >
                                <SendIcon sx={{ fontSize: "2rem" }} />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>



                <div className="footer-section3">
                    <div className="text primary2 xlarge bold center mt-large title">Stay in touch</div>
                    <div className="mt-small" style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
                        <IconButton
                            className="button primary rounded"
                            href={process.env.REACT_APP_FACEBOOK_URL}
                            target="_blank"
                        >
                            <FacebookIcon sx={{ fontSize: "2rem" }} />
                        </IconButton>
                        <IconButton
                            className="button primary rounded"
                            href={process.env.REACT_APP_INSTAGRAM_URL}
                            target="_blank"
                        >
                            <InstagramIcon sx={{ fontSize: "2rem" }} />
                        </IconButton>
                        <IconButton
                            className="button primary rounded"
                            href={process.env.REACT_APP_LINKEDIN_URL}
                            target="_blank"
                        >
                            <LinkedInIcon sx={{ fontSize: "2rem" }} />
                        </IconButton>

                    </div>
                </div>


                <div className="footer-section4 mt-large">
                    <IconButton
                        href="/"
                        sx={{ display: "flex", width: "fit-content", marginLeft: "auto", marginRight: "auto" }}
                    >
                        <Avatar
                            sx={{
                                width: "30%",
                                height: "100%",
                                "& img": { width: "100%", height: "100%", objectFit: "contain" }
                            }}
                            alt="Logo"
                            src={require("../img/logo.png")}
                        />
                    </IconButton>

                    <div className="text primary2 large center mt-medium title">Copyright © 2024 Farms4Climate. All Rights Reserved.</div>
                </div>
            </div>
        )
}


