import React, { useEffect, useState } from 'react';
import {
    Box, Typography, FormControl, InputLabel, Select, MenuItem, Grid, Button, Paper,
    Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel,
    IconButton, Toolbar, AppBar, Divider, TablePagination, TextField, Checkbox, Chip,
    InputBase,
    Tooltip,
    Fab,
    useMediaQuery
} from '@mui/material';

import { Menu as MenuIcon, MenuOpen as MenuOpenIcon, Close as CloseIcon } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { getFarmsData, setFarmsData } from '../../Assets/Utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SimulationResults from '../../Assets/Components/SimulationResults';
import { IconText } from '../../Assets/Components/General';
import BarChartIcon from '@mui/icons-material/BarChart';
import Loader from '../../Assets/Components/Loader';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function Statistics() {
    const [selectedFarm, setselectedFarm] = useState('all');
    const [selectedSimulations, setSelectedSimulations] = useState([]);
    const [confirmedSimulations, setConfirmedSimulations] = useState([]);
    const [dateFilterStart, setDateFilterStart] = useState('');
    const [dateFilterEnd, setDateFilterEnd] = useState('');
    const [isSettingsOpen, setisSettingsOpen] = useState(true);
    const [order, setOrder] = useState({
        0: 'asc'
    });
    const [orderBy, setOrderBy] = useState({
        0: "name"
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setsearchTerm] = useState("")
    const [farms, setFarms] = useState([])
    const [openRows, setOpenRows] = useState({});
    const [isLoading, setisLoading] = useState(true)
    const navigate = useNavigate()
    const [isExporting, setisExporting] = useState(false)
    const isMobile = useMediaQuery('(max-width:1024px)');


    const toggleRow = (farmId) => {
        setOpenRows((prevState) => ({
            ...prevState,
            [farmId]: !prevState[farmId]
        }));
    };

    const isSelected = (id) => selectedSimulations.includes(id);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        getFarmsData().then((savedFarms) => {
            setFarms(savedFarms)
            setisLoading(false)
        })
    }, []);

    const handleGroupSelect = (event) => {
        setselectedFarm(event.target.value);
    };

    const handleSelectSimulation = (simId, farmId) => {
        const id = `${simId}-${farmId}`;
        setSelectedSimulations(prev =>
            prev.includes(id) ? prev.filter(selected => selected !== id) : [...prev, id]
        );
    };


    const handleConfirmComparison = () => {
        setConfirmedSimulations(filteredSimulations().filter(sim => selectedSimulations.includes(sim.id + "-" + sim.farm_id)))
    };

    const handleClearSelections = () => {
        setSelectedSimulations([]);
        setConfirmedSimulations([]);
    };
    const exportToPdf = () => {
        const element = document.querySelector('.simulation-result');

        if (!element) {
            console.error('No se encontró el contenedor de resultados.');
            return;
        }


        html2canvas(element, { scale: 2, scrollY: 0 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 0.5);
            const pdf = new jsPDF('p', 'mm', 'a4');

            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            let heightLeft = imgHeight;
            let position = 0;

            while (heightLeft > 0) {
                const pageImgHeight = Math.min(pageHeight, heightLeft);

                pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
                position -= pageHeight;
                if (heightLeft > 0) {
                    pdf.addPage();
                }
            }

            pdf.save('Simulation Results - ' + new Date().toLocaleString() + ".pdf")
            setisExporting(false)
        });
    };
    const filteredSimulations = (farm = null) => {
        let sims = farm ? farm.simulationRecords : farms.flatMap(farm => farm.simulationRecords);

        if (selectedFarm !== "all") {
            sims = sims.filter(sim => sim.farm_id === selectedFarm.id);
        }

        return sims.filter(sim => {
            const simDate = new Date(sim.date);
            const startDate = dateFilterStart ? new Date(dateFilterStart) : new Date(-8640000000000000);
            const endDate = dateFilterEnd ? new Date(dateFilterEnd) : new Date(8640000000000000);
            return simDate >= startDate && simDate <= endDate;
        }).filter(sim => {
            const farmName = farms.find(farm => farm.id === sim.farm_id)?.name.toLowerCase() || '';
            return sim.name.toLowerCase().includes(searchTerm.toLowerCase()) || farmName.includes(searchTerm.toLowerCase());
        });
    };


    const handleRequestSort = (event, farm_id, property) => {
        const isAsc = orderBy[farm_id] === property && order[farm_id] === 'asc';
        const newOrder = { ...order }
        newOrder[farm_id] = isAsc ? 'desc' : 'asc'

        const newOrderBy = { ...orderBy }
        newOrderBy[farm_id] = property

        setOrder(newOrder);
        setOrderBy(newOrderBy);
    };

    const sortedSimulations = (farm) => {
        const getValue = (obj, path) => {
            return path.split('.').reduce((value, key) => value && value[key], obj);
        };



        return filteredSimulations(farm).sort((a, b) => {
            const valueA = getValue(a, orderBy[farm.id] ? orderBy[farm.id] : 'asc');
            const valueB = getValue(b, orderBy[farm.id] ? orderBy[farm.id] : 'asc');

            if (order[farm.id] === 'asc') {
                return valueA < valueB ? -1 : (valueA > valueB ? 1 : 0);
            } else {
                return valueA > valueB ? -1 : (valueA < valueB ? 1 : 0);
            }
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    function canDeleteSimulation(simulation) {
        const farm = farms.find(farm => farm.id === simulation.farm_id);  // Use `===` here
        return farm && farm.simulationRecords.length > 1;
    }


    async function handleSimulationDelete(simulation) {
        const farm = farms.find(farm => farm.id === simulation.farm_id);  // Use `===` for correct comparison
        if (farm) {
            const newSimulations = farm.simulationRecords.filter(cSim => cSim.id !== simulation.id);

            const updatedFarms = farms.map(cFarm => {
                if (cFarm.id === farm.id) {
                    return {
                        ...cFarm,
                        simulationRecords: newSimulations
                    };
                }
                return cFarm;
            });

            setFarms(updatedFarms)
            await setFarmsData(updatedFarms)
        }
    }


    return (
        <Loader isLoading={isLoading}>
            <div className="subheader-container">
                <div className="header">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                            sx={{
                                mr: 2, '& .MuiSvgIcon-root': {
                                    color: "#fff"
                                }
                            }}
                            onClick={() => {
                                setisSettingsOpen(!isSettingsOpen);
                            }}
                        >
                            {isSettingsOpen ? <MenuOpenIcon /> : <MenuIcon />}
                        </IconButton>
                        <div className="text primary2 bold large title">
                            Reports
                        </div>
                    </div>
                </div>

                <div className={"main-sidebar " + (isSettingsOpen ? "sidebar open" : "sidebar")}>
                    <div className='sidebar-header'>
                        <IconText icon={<BarChartIcon sx={{ mr: 2, color: "#fff !important" }} />} text={<div className="text primary2 large">Reports</div>} />

                        <IconButton onClick={() => setisSettingsOpen(false)} sx={{
                            '& .MuiSvgIcon-root': {
                                color: "#fff"
                            }
                        }}>
                            <CloseIcon style={isMobile ? { fontSize: "3rem" } : {}} />
                        </IconButton>
                    </div>

                    <Divider sx={{ mb: 3, bgcolor: "#fff" }} />

                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }} className='input primary2'>
                        <FormControl margin="normal" style={{ width: "40%" }}>
                            <InputLabel><div className='text primary2 bold large'>Select Farm</div></InputLabel>
                            <Select value={selectedFarm} onChange={handleGroupSelect} >
                                <MenuItem style={isMobile ? { fontSize: "2rem" } : {}} value={"all"}>
                                    All Farms
                                </MenuItem>
                                {farms.length > 0 ? (
                                    farms.map((group, index) => (
                                        <MenuItem key={index} style={isMobile ? { fontSize: "2rem" } : {}} value={group}>
                                            {farms[index].name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem style={isMobile ? { fontSize: "2rem" } : {}} disabled>No groups available</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    {selectedFarm && (
                        <>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                <TextField
                                    label="Start Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    sx={{ mr: 2 }}
                                    value={dateFilterStart}
                                    onChange={(e) => setDateFilterStart(e.target.value)}
                                    className='input primary2'
                                />
                                <TextField
                                    label="End Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    sx={{ mr: 2 }}
                                    value={dateFilterEnd}
                                    onChange={(e) => setDateFilterEnd(e.target.value)}
                                    className='input primary2'
                                />
                            </Grid>


                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }} sx={{ mt: 2 }}>
                                <Paper component="form">
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        className='input primary'
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={(e) => setsearchTerm(e.target.value)}
                                    />
                                    <IconButton sx={{ p: '5px' }}>
                                        <SearchIcon style={isMobile ? { fontSize: "3rem" } : {}} />
                                    </IconButton>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <div className='text primary2 large'>
                                    Selected Simulations
                                </div>

                                <Box style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
                                    {selectedSimulations.map((selectedSim, index) => {
                                        const [simId, farmId] = selectedSim.split("-");

                                        const farm = farms.find(farm => farm.id == farmId);
                                        const simulation = farm?.simulationRecords.find(sim => sim.id == simId);

                                        if (farm && simulation) {
                                            return (
                                                <Chip
                                                    key={index}
                                                    label={`${farm.name} - ${simulation.name}`}
                                                    onDelete={() => handleSelectSimulation(simId, farmId)}
                                                    color="primary"
                                                    className='text primary2 large'
                                                    sx={{ p: isMobile ? "1.5rem" : "0.5rem", "& .MuiSvgIcon-root": { fontSize: isMobile ? "3rem" : "1.5rem" } }}
                                                />
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}

                                </Box>
                            </Grid>


                            <Paper>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        indeterminate={selectedSimulations.length > 0 && selectedSimulations.length < filteredSimulations().length}
                                                        checked={filteredSimulations().length > 0 && selectedSimulations.length === filteredSimulations().length}
                                                        onChange={(event) => {
                                                            if (event.target.checked) {

                                                                if (selectedSimulations.length) {
                                                                    // if already some simulation selected then select all
                                                                    setSelectedSimulations(filteredSimulations().map((sim) => sim.id + "-" + sim.farm_id));
                                                                } else {
                                                                    // select only last record of each farm
                                                                    const sortedSims = filteredSimulations().sort((a, b) => {
                                                                        if (a.farm_id === b.farm_id) {
                                                                            return new Date(b.date) - new Date(a.date);
                                                                        }
                                                                        return a.farm_id - b.farm_id;
                                                                    });

                                                                    const result = [];
                                                                    const latestEntries = new Map();

                                                                    for (const item of sortedSims) {
                                                                        if (!latestEntries.has(item.farm_id)) {
                                                                            latestEntries.set(item.farm_id, item);
                                                                            result.push(item);
                                                                        }
                                                                    }



                                                                    setSelectedSimulations(result.map((sim) => sim.id + "-" + sim.farm_id));
                                                                }

                                                            } else {
                                                                setSelectedSimulations([]);
                                                            }
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    Farm Name
                                                </TableCell>
                                                <TableCell>
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {farms.map((farm) => {
                                                const farmSimulations = filteredSimulations(farm)
                                                if (!farmSimulations.length) {
                                                    return (null)
                                                }
                                                return (
                                                    <React.Fragment key={farm.id}>
                                                        {/* Farm Row */}
                                                        <TableRow hover role="checkbox">
                                                            <TableCell>
                                                                <IconButton
                                                                    aria-label="expand row"
                                                                    size="small"
                                                                    onClick={() => toggleRow(farm.id)}
                                                                >
                                                                    {openRows[farm.id] ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    indeterminate={selectedSimulations.some(selectedSim => {
                                                                        const [simId, farmId] = selectedSim.split("-");
                                                                        return farmId == farm.id
                                                                    }) && selectedSimulations.filter(selectedSim => {
                                                                        const [simId, farmId] = selectedSim.split("-");
                                                                        return farmId == farm.id
                                                                    }).length < farmSimulations.length}
                                                                    checked={farmSimulations.length > 0 && farmSimulations.every(sim => selectedSimulations.includes(sim.id + "-" + sim.farm_id))}
                                                                    onChange={(event) => {
                                                                        if (event.target.checked) {
                                                                            if (farmSimulations.some(sim => selectedSimulations.includes(sim.id + "-" + sim.farm_id))) {

                                                                                // if one of current farm simulation selected then select all
                                                                                setSelectedSimulations([
                                                                                    ...selectedSimulations,
                                                                                    ...farmSimulations.map(sim => sim.id + "-" + sim.farm_id)
                                                                                ]);
                                                                            } else {
                                                                                // select only last record of the farm
                                                                                const lastSimRecord = (farmSimulations.sort((a, b) => new Date(b.date) - new Date(a.date)))[0]
                                                                                const lastSimRecordId = lastSimRecord.id + "-" + lastSimRecord.farm_id

                                                                                if (!selectedSimulations.includes(lastSimRecordId)) {
                                                                                    setSelectedSimulations([
                                                                                        ...selectedSimulations,
                                                                                        lastSimRecordId
                                                                                    ]);
                                                                                } else {
                                                                                    setSelectedSimulations(
                                                                                        selectedSimulations.filter(id => id != lastSimRecordId)
                                                                                    );
                                                                                }
                                                                            }

                                                                        } else {
                                                                            setSelectedSimulations(
                                                                                selectedSimulations.filter(sim => !farmSimulations.some(fSim => fSim.id + "-" + fSim.farm_id === sim))
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>{farm.name}</TableCell>
                                                            <TableCell>
                                                                <Tooltip title="View">
                                                                    <IconButton className='button primary medium' onClick={() => {
                                                                        navigate("/user/simulation/" + farm.id)
                                                                    }}>
                                                                        <VisibilityIcon style={isMobile ? { fontSize: "3rem" } : {}} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>

                                                        {/* Collapsible Row for Simulation Records */}
                                                        <TableRow>
                                                            <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                                                <Collapse in={openRows[farm.id]} timeout="auto" unmountOnExit>
                                                                    <Box margin={1}>
                                                                        <Typography variant="h6" gutterBottom component="div">
                                                                            Simulations History
                                                                        </Typography>
                                                                        <Table size="small" aria-label="simulations">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell padding="checkbox"></TableCell>
                                                                                    <TableCell>
                                                                                        <TableSortLabel
                                                                                            active={orderBy[farm.id] === 'name'}
                                                                                            direction={orderBy[farm.id] === 'name' ? order[farm.id] : 'asc'}
                                                                                            onClick={(event) => handleRequestSort(event, farm.id, 'name')}
                                                                                        >
                                                                                            Simulation Record Name
                                                                                        </TableSortLabel>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <TableSortLabel
                                                                                            active={orderBy[farm.id] === 'date'}
                                                                                            direction={orderBy[farm.id] === 'date' ? order[farm.id] : 'asc'}
                                                                                            onClick={(event) => handleRequestSort(event, farm.id, 'date')}
                                                                                        >
                                                                                            Date
                                                                                        </TableSortLabel>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        Action
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {sortedSimulations(farm).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sim) => {
                                                                                    const isItemSelected = isSelected(sim.id + "-" + sim.farm_id);
                                                                                    return (
                                                                                        <TableRow
                                                                                            hover
                                                                                            onClick={() => handleSelectSimulation(sim.id, sim.farm_id)}
                                                                                            role="checkbox"
                                                                                            aria-checked={isItemSelected}
                                                                                            tabIndex={-1}
                                                                                            selected={isItemSelected}
                                                                                            key={sim.id}
                                                                                        >
                                                                                            <TableCell padding="checkbox">
                                                                                                <Checkbox
                                                                                                    checked={isItemSelected}
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell>{sim.name}</TableCell>
                                                                                            <TableCell>{sim.date}</TableCell>
                                                                                            <TableCell>
                                                                                                <Tooltip title="Delete">
                                                                                                    <IconButton className='button error medium' disabled={!canDeleteSimulation(sim)} onClick={(e) => {
                                                                                                        handleSimulationDelete(sim)
                                                                                                        e.stopPropagation()
                                                                                                    }}>
                                                                                                        <DeleteIcon />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    );
                                                                                })}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </Box>
                                                                </Collapse>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filteredSimulations().length}  // This ensures it handles all simulations
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />

                            </Paper>





                            <Grid container spacing={2} justifyContent="center" sx={{ mt: 1, mb: 3 }}>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        className='button primary medium'
                                        startIcon={<RestartAltIcon sx={{ fontSize: "2rem !important" }} />}
                                        onClick={handleClearSelections}
                                        disabled={selectedSimulations.length === 0}
                                    >
                                        Reset Simulation
                                    </Button>


                                </Grid>
                            </Grid>


                            <Tooltip title="Run Simulation">
                                <Fab
                                    className='button primary rounded'
                                    aria-label="save"
                                    onClick={handleConfirmComparison}
                                    disabled={selectedSimulations.length === 0}
                                    sx={{
                                        position: 'fixed',
                                        bottom: '20px',
                                        right: isMobile ? "9rem" : "5rem",
                                        zIndex: 4,
                                    }}
                                    style={isMobile ? {
                                        width: isMobile ? "7rem" : "auto",
                                        height: isMobile ? "7rem" : "auto"
                                    } : {}}
                                >
                                    <PlayArrowIcon style={isMobile ? { fontSize: "4rem" } : {}} />
                                </Fab>
                            </Tooltip>
                            <Tooltip title="Generate PDF">
                                <Fab
                                    className='button secondary rounded'
                                    aria-label="pdf"
                                    onClick={() => {
                                        setisExporting(true)
                                        if (isSettingsOpen) {
                                            setisSettingsOpen(false)
                                            setTimeout(() => {
                                                exportToPdf()
                                            }, 1000)
                                        } else {
                                            exportToPdf()
                                        }
                                    }}
                                    disabled={confirmedSimulations.length === 0 || isExporting}
                                    sx={{
                                        position: 'fixed',
                                        bottom: '20px',
                                        right: isMobile ? "1rem" : "1rem",
                                        zIndex: 4,
                                    }}
                                    style={isMobile ? {
                                        width: isMobile ? "7rem" : "auto",
                                        height: isMobile ? "7rem" : "auto"
                                    } : {}}
                                >
                                    <PictureAsPdfIcon style={isMobile ? { fontSize: "4rem" } : {}} />
                                </Fab>
                            </Tooltip>
                        </>
                    )}
                </div>
            </div>


            <div className="container">
                <div className={isSettingsOpen ? "sidebar-body open" : "sidebar-body"}>
                    <SimulationResults simulationRecords={confirmedSimulations} farms={farms} small={true} />
                </div>
            </div>
        </Loader>
    );
}