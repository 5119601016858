import React, { useContext, useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, FeatureGroup, Popup, Marker } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import 'leaflet-draw/dist/leaflet.draw.css';
import { coordinatesModel, FarmRecordModel, simulationRecordModel } from '../../Assets/shared/InputModels.js';
import mapMarker from "../../Assets/img/marker.png"
import { WiDaySunny } from 'react-icons/wi';
import { TextField, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Box, Card, CardContent, Button, Divider, Stack, Collapse, Fab, Tooltip, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, useMediaQuery } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import FlagIcon from '@mui/icons-material/Flag';
import CloudIcon from '@mui/icons-material/Cloud';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import SaveIcon from '@mui/icons-material/Save';
import { fetchWeatherData, getFarmLastSimulationRecord, getFarmsData, getLastCopyNumber, getPolygonLayerBounds, getPolygonTurfCenter, getRandomColor, getUserPosition, isSimulationNameExist, removeCopyPattern, setFarmsData } from '../../Assets/Utils.js';
import { getFarmMarkerLabel } from '../../Assets/Components/FarmMarkerLabel.js';
import { AlertContext } from '../../Assets/Components/Alerter.js';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SpeedIcon from '@mui/icons-material/Speed';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import MapIcon from '@mui/icons-material/Map';
import { useParams } from 'react-router-dom';
import Loader from '../../Assets/Components/Loader.js';
import FarmInputForm from '../../Assets/Components/FarmInputForm.js';
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Add, Edit, Delete } from '@mui/icons-material'
import { GiGrass, GiWheat, GiCow } from 'react-icons/gi';
import LandscapeIcon from '@mui/icons-material/Landscape';
import GrassIcon from '@mui/icons-material/Grass';
import FenceIcon from '@mui/icons-material/Fence';
import PetsIcon from '@mui/icons-material/Pets';
import TransgenderIcon from '@mui/icons-material/Transgender';
import ScaleIcon from '@mui/icons-material/Scale';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import YardIcon from '@mui/icons-material/Yard';
import { Countries } from '../../Assets/countries.js';




export default function FarmManagement() {
    const { passedFarmId } = useParams();
    const [ispassedFarmId, setispassedFarmId] = useState(passedFarmId ? true : false)

    const [savedFarmData, setsavedFarmData] = useState([])
    const [farms, setFarms] = useState([]);
    const [userPosition, setuserPosition] = useState(coordinatesModel(0, 0));
    const [selectedFarmId, setselectedFarmId] = useState(-1);
    const [selectedFarm, setSelectedFarm] = useState(null)
    const [isLoading, setisLoading] = useState(true);
    const [isFarmsDataChanged, setisFarmsDataChanged] = useState(false)
    const [isAdding, setisAdding] = useState(false)
    const [isEditing, setisEditing] = useState(false)
    const [isDeleting, setisDeleting] = useState(false)
    const { setAlert } = useContext(AlertContext)
    const farmRefs = useRef([]);
    const mapRef = useRef(null)
    const farmsRef = useRef([])
    const mapMarkerRef = useRef(null)
    const featureGroupRef = useRef(null)
    const customIcon = L.icon({
        iconUrl: mapMarker,
        iconSize: [50, 50],
        iconAnchor: [25, 50],
        popupAnchor: [0, -50]
    })
    const isMobile = useMediaQuery('(max-width:1024px)');



    const scrollToComponent = (id) => {
        if (farmRefs.current[id]) {
            farmRefs.current[id].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }


    const handleViewClick = (farm) => {
        setSelectedFarm(farm)
    }

    const handleCloseDialog = () => {
        setSelectedFarm(null)
    }

    const handleDelete = (farmId) => {
        setFarms(prevAreas => prevAreas.filter(farm => {
            if (farm.id == farmId) {
                mapRef.current.removeLayer(farm.marker)
                mapRef.current.removeLayer(farm.layer)
                return false
            }
            return true
        }))
    }





    useEffect(() => {
        getUserPosition(setAlert, (userPos) => {
            setuserPosition({ lat: userPos.lat, lon: userPos.lon })
        })
    }, [])

    useEffect(() => {
        if (mapRef.current && featureGroupRef.current && userPosition.lat) {
            Init()
        }
    }, [mapRef.current, featureGroupRef.current, userPosition])



    async function Init() {
        mapRef.current.setView([userPosition.lat, userPosition.lon])



        try {
            await fetchFarms()
        } catch (err) {

        }


        setisLoading(false)
    }


    useEffect(() => {
        farmsRef.current = farms;



        // check if farm id is passed as query param
        if (ispassedFarmId && farms.length) {
            onFarmSelect(Number(passedFarmId))
            setispassedFarmId(false)
        }


        // simulator data change detector
        checkSimulatorChanges().then((isChanged) => {
            setisFarmsDataChanged(isChanged)
        })
    }, [farms])


    async function checkSimulatorChanges() {
        if (savedFarmData.length != farms.length) {
            return true
        }

        for (let a = 0; a < farms.length; a++) {
            if (savedFarmData[a].name != farms[a].name ||
                savedFarmData[a].color != farms[a].color ||
                JSON.stringify(farms[a].currentSimulationRecord) != JSON.stringify(getFarmLastSimulationRecord(savedFarmData[a]))) {
                return true
            }
        }
        return false
    }


    // load farms from DB
    const fetchFarms = async () => {
        let savedFarms = await getFarmsData()




        const promises = []

        savedFarms = savedFarms.map((farm) => {
            const layer = L.geoJSON(farm.geoLayer).getLayers()[0]

            layer.options.farm_id = farm.id


            featureGroupRef.current.addLayer(layer);

            farm.layer = layer
            farm.currentSimulationRecord = { ...getFarmLastSimulationRecord(farm) }




            promises.push(fetchWeatherData(farm.currentSimulationRecord.centerCoordinates.lat, farm.currentSimulationRecord.centerCoordinates.lon))


            return farm
        })



        // asign async weather data
        const results = await Promise.all(promises)
        for (let a = 0; a < results.length; a++) {
            savedFarms[a].weather = results[a]
            updateLayerMap(savedFarms[a])
        }



        setFarms(savedFarms)
        setsavedFarmData(savedFarms)
    }

    const updateFarm = (farm_id, newFarm) => {
        updateLayerMap(newFarm)
        setFarms(prevAreas => prevAreas.map(farm => farm.id === farm_id ? newFarm : farm));
    };



    const onAreaCreated = async (e) => {
        // cancel add farm mode
        cancelAdd()


        const layer = e.layer;

        const color = getRandomColor()

        const name = `Farm ${farmsRef.current.length + 1}`
        const farm_id = farmsRef.current.length ? (farmsRef.current.sort((a, b) => a.id - b.id)[farmsRef.current.length - 1].id + 1) : 0
        const simulation_record = simulationRecordModel()
        simulation_record.farm_id = farm_id
        simulation_record.bounds = getPolygonLayerBounds(layer)
        simulation_record.centerCoordinates = getPolygonTurfCenter(simulation_record.bounds)



        layer.options.farm_id = farm_id


        const newFarm = FarmRecordModel(
            farm_id,
            name,
            layer,
            null,
            layer.toGeoJSON(),
            await fetchWeatherData(simulation_record.centerCoordinates.lat, simulation_record.centerCoordinates.lon),
            color,
            [],
            simulation_record
        )


        setselectedFarmId(farm_id)
        updateLayerMap(newFarm)
        setFarms(prevAreas => [...prevAreas, newFarm]);
    };

    const onAreaEdited = (e) => {
        const updatedLayers = e.layers.getLayers()


        updatedLayers.map(async (layer) => {
            const updatedfarm = farmsRef.current.find(a => a.id === layer.options.farm_id);


            // update farm geo + simulation record
            updatedfarm.currentSimulationRecord.bounds = getPolygonLayerBounds(layer)
            updatedfarm.currentSimulationRecord.centerCoordinates = getPolygonTurfCenter(updatedfarm.currentSimulationRecord.bounds)
            updatedfarm.weather = await fetchWeatherData(updatedfarm.currentSimulationRecord.centerCoordinates.lat, updatedfarm.currentSimulationRecord.centerCoordinates.lon)


            // Update the area with new marker and layer
            updateFarm(updatedfarm.id, { ...updatedfarm, layer: layer });
        });
    };

    const onAreaDeleted = (e) => {
        const deletedLayers = e.layers.getLayers()


        deletedLayers.map(layer => {
            setFarms(prevAreas => prevAreas.filter(farm => {
                if (farm.id == layer.options.farm_id) {
                    mapRef.current.removeLayer(farm.marker)
                    return false
                }
                return true
            }));
        });
    };




    function isSimulationDataChanged(farm) {
        const savedSimulation = getFarmLastSimulationRecord(farm)
        const current_simulation = farm.currentSimulationRecord


        return (JSON.stringify(savedSimulation) != JSON.stringify(current_simulation))
    }


    async function saveChanges() {
        // save farms to DB only if farm map changed or simulation record changed
        // each changes is saved in a unique simulation record
        let newFarms = farms

        newFarms = newFarms.map(farm => {


            if (isSimulationDataChanged(farm)) {
                // save new simulation record
                farm.currentSimulationRecord.id += 1
                farm.currentSimulationRecord.date = new Date()

                // check if new simulation name already exist then add unique one
                let nameTryCount = getLastCopyNumber(farm.simulationRecords)
                while (isSimulationNameExist(farm.currentSimulationRecord, farm)) {
                    nameTryCount++

                    farm.currentSimulationRecord.name = removeCopyPattern(farm.currentSimulationRecord.name) + " (Copy " + nameTryCount + ")"
                }



                farm.simulationRecords.push(farm.currentSimulationRecord)
            }

            return farm
        })


        setAlert("Changes saved !")
        setFarms(newFarms)
        setsavedFarmData(newFarms)
        await setFarmsData(newFarms)
    }



    const updateLayerMap = (farm) => {
        const centerCoordinates = getPolygonTurfCenter(getPolygonLayerBounds(farm.layer))

        // Remove the old marker if it exists
        if (farm.marker) {
            mapRef.current.removeLayer(farm.marker);
        }

        // Create a new marker with updated center position and name

        const label = L.divIcon({
            className: 'farm-marker',
            html: getFarmMarkerLabel(farm),
            iconSize: [110, 100], // Adjust size as needed
            iconAnchor: [75, 50] // Adjust anchor as needed
        });

        const marker = L.marker([centerCoordinates.lat, centerCoordinates.lon], { icon: label });
        marker.addTo(mapRef.current);

        farm.marker = marker

        farm.geoLayer = farm.layer.toGeoJSON()


        farm.layer.setStyle({
            color: farm.color,
            weight: 3,
            opacity: 0.7,
            fillOpacity: 0.3
        });
    }






    const onFarmSelect = (farm_id) => {
        if (selectedFarmId === farm_id) {
            setselectedFarmId(null)
            return
        }
        setselectedFarmId(farm_id)


        const farm = farms.find(a => a.id === farm_id);
        if (farm && mapRef.current) {
            const map = mapRef.current;
            map.setView(farm.layer.getBounds().getCenter());
        }
    };




    const enableAddMode = () => {
        document.querySelector(".leaflet-draw-draw-polygon").click()
        setisAdding(true)
    };

    const enableEditMode = () => {
        document.querySelector(".leaflet-draw-edit-edit").click()
        setisEditing(true)
    };

    const enableDeleteMode = () => {
        document.querySelector(".leaflet-draw-edit-remove").click()
        setisDeleting(true)
    };


    const cancelAdd = () => {
        document.querySelector(".leaflet-draw-actions.leaflet-draw-actions-top.leaflet-draw-actions-bottom").lastChild.firstChild.click()
        setisAdding(false)
    }


    const saveEdit = () => {
        document.querySelector(".leaflet-draw-actions.leaflet-draw-actions-top").firstChild.firstChild.click()
        setisEditing(false)
    }
    const cancelEdit = () => {
        document.querySelector(".leaflet-draw-actions.leaflet-draw-actions-top").lastChild.firstChild.click()
        setisEditing(false)
    }


    const saveDelete = () => {
        document.querySelector(".leaflet-draw-actions.leaflet-draw-actions-bottom").firstChild.firstChild.click()
        setisDeleting(false)
    }
    const cancelDelete = () => {
        document.querySelector(".leaflet-draw-actions.leaflet-draw-actions-bottom").children[1].firstChild.click()
        setisDeleting(false)
    }

    return (
        <>
            <Loader isLoading={isLoading}></Loader>
            <div className='container'>
                <div className='text primary xxlarge'>Farm Management</div>



                <div className='card-container'>

                    <Box sx={{ display: 'flex', height: '70vh', overflow: 'hidden' }}>
                        <Box sx={{ flex: 2, position: 'relative' }}>
                            <MapContainer center={[userPosition.lat, userPosition.lon]} zoom={13} style={{ height: '100%', zIndex: 3 }} ref={mapRef}>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                <Marker icon={customIcon} position={[userPosition.lat, userPosition.lon]}>
                                    <Popup ref={mapMarkerRef}>{"Your position"}</Popup>
                                </Marker>

                                <FeatureGroup ref={featureGroupRef}>
                                    <EditControl
                                        position="topright"
                                        onCreated={onAreaCreated}
                                        onDeleted={onAreaDeleted}
                                        onEdited={onAreaEdited}
                                        draw={{ rectangle: false, circle: false, polyline: false, circlemarker: false, marker: false }}
                                    />
                                </FeatureGroup>
                            </MapContainer>


                            <Box sx={{ position: 'absolute', bottom: 20, right: 20, zIndex: 4 }}>
                                <Stack spacing={1}>
                                    <Box sx={{ position: 'relative' }}>
                                        <Tooltip title="Add Farm">
                                            <Fab
                                                className='button primary rounded'
                                                aria-label="add"
                                                onClick={enableAddMode}
                                                disabled={isEditing || isDeleting || isAdding}
                                            >
                                                <AddIcon style={isMobile ? { fontSize: "3rem" } : {}} />
                                            </Fab>
                                        </Tooltip>
                                        <Collapse in={isAdding} timeout="auto">
                                            <Box sx={{ display: 'flex', flexDirection: 'row', position: "absolute", left: '-100%', top: 0 }}>
                                                <Fab
                                                    className='button secondary rounded small'
                                                    aria-label="cancel"
                                                    onClick={cancelAdd}

                                                >
                                                    Cancel
                                                </Fab>
                                            </Box>
                                        </Collapse>
                                    </Box>


                                    <Box sx={{ position: 'relative' }}>
                                        <Tooltip title="Edit Farm">
                                            <Fab
                                                className='button secondary rounded'
                                                aria-label="edit"
                                                onClick={enableEditMode}
                                                disabled={isEditing || isDeleting || isAdding || !farms.length || (document.querySelector(".leaflet-draw-edit-edit.leaflet-disabled") ? true : false)}
                                            >
                                                <EditIcon style={isMobile ? { fontSize: "3rem" } : {}} />
                                            </Fab>
                                        </Tooltip>
                                        <Collapse in={isEditing} timeout="auto">
                                            <Box sx={{ display: 'flex', flexDirection: 'row', position: "absolute", left: '-100%', top: 0 }}>
                                                <Fab
                                                    className='button success rounded small'
                                                    aria-label="save"
                                                    onClick={saveEdit}
                                                >
                                                    Save
                                                </Fab>
                                                <Fab
                                                    className='button secondary rounded small'
                                                    aria-label="cancel"
                                                    onClick={cancelEdit}
                                                >
                                                    Cancel
                                                </Fab>
                                            </Box>
                                        </Collapse>
                                    </Box>


                                    <Box sx={{ position: 'relative' }}>
                                        <Tooltip title="Delete Farm">
                                            <Fab
                                                className='button error rounded'
                                                aria-label="delete"
                                                onClick={enableDeleteMode}
                                                disabled={isDeleting || isEditing || isAdding || !farms.length || (document.querySelector(".leaflet-draw-edit-remove.leaflet-disabled") ? true : false)}
                                            >
                                                <DeleteIcon style={isMobile ? { fontSize: "3rem" } : {}} />
                                            </Fab>
                                        </Tooltip>
                                        <Collapse in={isDeleting} timeout="auto">
                                            <Box sx={{ display: 'flex', flexDirection: 'row', position: "absolute", left: '-100%', top: 0 }}>
                                                <Fab
                                                    className='button success rounded small'
                                                    aria-label="save"
                                                    onClick={saveDelete}
                                                >
                                                    Save
                                                </Fab>
                                                <Fab
                                                    className='button secondary rounded small'
                                                    aria-label="cancel"
                                                    onClick={cancelDelete}
                                                >
                                                    Cancel
                                                </Fab>
                                            </Box>
                                        </Collapse>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>


                        {farms.length ?
                            <div className='card-container'>
                                <div className='text primary xlarge center'>Farm List</div>



                                {farms.map((farm) => (
                                    <Accordion
                                        sx={{
                                            border: "1px solid #bbb",
                                            boxShadow: `-10px 0px 3px 1px ${farm.color}`,
                                            margin: "25px !important",
                                            borderRadius: 5,

                                            '& .MuiAccordionSummary-root': {
                                                backgroundColor: selectedFarmId === farm.id ? "#bbbbbb61" : "inherit",
                                                borderRadius: 5
                                            },
                                            '& .MuiAccordionSummary-root:hover': {
                                                backgroundColor: "#bbbbbb8c"
                                            }

                                        }}

                                        expanded={selectedFarmId === farm.id}
                                        onChange={() => {
                                            onFarmSelect(farm.id)
                                        }}

                                        key={farm.id}
                                    >
                                        <AccordionSummary sx={{
                                            "& .MuiAccordionSummary-content": {
                                                display: "flex", justifyContent: "space-between", alignItems: "center"
                                            }
                                        }}>

                                            <div>{farm.name}</div>

                                            <div>
                                                <Tooltip title="View" sx={{ ml: 1 }}>
                                                    <IconButton className='button primary' onClick={() => handleViewClick(farm)}>
                                                        <VisibilityIcon style={isMobile ? { fontSize: "3rem" } : {}} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit" sx={{ ml: 1 }}>
                                                    <IconButton className='button secondary' onClick={() => scrollToComponent(farm.id)}>
                                                        <Edit style={isMobile ? { fontSize: "3rem" } : {}} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete" sx={{ ml: 1 }}>
                                                    <IconButton className='button error' disabled={isDeleting} onClick={() => handleDelete(farm.id)}>
                                                        <Delete style={isMobile ? { fontSize: "3rem" } : {}} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>

                                        </AccordionSummary>
                                    </Accordion>
                                ))}

                            </div>
                            :
                            ""
                        }


                        {selectedFarm && (
                            <Dialog open={Boolean(selectedFarm)} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                                <DialogTitle><div className='text primary bold xlarge'>{selectedFarm.name} - Details</div></DialogTitle>
                                <DialogContent>

                                    <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                        <div className='icon primary xlarge'><WiDaySunny /></div>
                                        <div className='text primary bold xlarge'>Weather</div>
                                    </div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: "0px 0px 5px 1px #FF5722" }}>
                                                <CardContent>
                                                    <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                        <div className='icon primary xlarge'><ThermostatIcon /></div>
                                                        <div className='text primary bold xlarge'>Temperature</div>
                                                    </div>
                                                    <div className='text primary xlarge center mt-small'>{selectedFarm.weather.temperature} °C</div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: "0px 0px 5px 1px #03A9F4" }}>
                                                <CardContent>
                                                    <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                        <div className='icon primary xlarge'><SpeedIcon /></div>
                                                        <div className='text primary bold xlarge'>Wind Speed</div>
                                                    </div>
                                                    <div className='text primary xlarge center mt-small'>{selectedFarm.weather.windSpeed} km/h</div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: "0px 0px 5px 1px #4CAF50" }}>
                                                <CardContent>
                                                    <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                        <div className='icon primary xlarge'><WaterDropIcon /></div>
                                                        <div className='text primary bold xlarge'>Humidity</div>
                                                    </div>
                                                    <div className='text primary xlarge center mt-small'>{selectedFarm.weather.humidity} %</div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: "0px 0px 5px 1px #2196F3" }}>
                                                <CardContent>
                                                    <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                        <div className='icon primary xlarge'><CloudIcon /></div>
                                                        <div className='text primary bold xlarge'>Precipitation</div>
                                                    </div>
                                                    <div className='text primary xlarge center mt-small'>{selectedFarm.weather.precipitation} mm</div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: "0px 0px 5px 1px #FFC107" }}>
                                                <CardContent>
                                                    <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                        <div className='icon primary xlarge'><WbSunnyIcon /></div>
                                                        <div className='text primary bold xlarge'>Radiation</div>
                                                    </div>
                                                    <div className='text primary xlarge center mt-small'>{selectedFarm.weather.radiation.toFixed(2)} MJ/m²</div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: "0px 0px 5px 1px #9E9E9E" }}>
                                                <CardContent>
                                                    <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                        <div className='icon primary xlarge'><MapIcon /></div>
                                                        <div className='text primary bold xlarge'>Description</div>
                                                    </div>
                                                    <div className='text primary xlarge center mt-small'>{selectedFarm.weather.description}</div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={isMobile ? 6 : 4}>
                                            <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: "0px 0px 5px 1px #E91E63" }}>
                                                <CardContent>
                                                    <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                        <div className='icon primary xlarge'><FlagIcon /></div>
                                                        <div className='text primary bold xlarge'>Country</div>
                                                    </div>
                                                    <div className='text primary xlarge center mt-small'>{Countries[selectedFarm.weather.country] || selectedFarm.weather.country}</div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>


                                    <Divider className='divider secondary small' />



                                    <div className='card-container'>
                                        <div className='text primary bold xlarge mb-small'>Simulation Record</div>
                                        <div className='text primary large'><strong>Name:</strong> {selectedFarm.currentSimulationRecord.name}</div>

                                        <Divider className='divider secondary small' />


                                        <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                            <div className='icon primary xlarge'><GiCow /></div>
                                            <div className='text primary bold xlarge'>Herd</div>
                                        </div>

                                        <Grid container spacing={2}>
                                            <Grid item xs={isMobile ? 6 : 4}>
                                                <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                    <div className='icon primary large'><PetsIcon /></div>
                                                    <div className='text primary large'><strong>Herd Size:</strong> {selectedFarm.currentSimulationRecord.herdProperties.herdSize}</div>
                                                </div>

                                            </Grid>
                                            <Grid item xs={isMobile ? 6 : 4}>
                                                <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                    <div className='icon primary large'><TransgenderIcon /></div>
                                                    <div className='text primary large'><strong>Breed:</strong> {selectedFarm.currentSimulationRecord.herdProperties.breed}</div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={isMobile ? 6 : 4}>
                                                <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                    <div className='icon primary large'><ScaleIcon /></div>
                                                    <div className='text primary large'><strong>Average Weight:</strong> {selectedFarm.currentSimulationRecord.herdProperties.weight} Kg</div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={isMobile ? 6 : 4}>
                                                <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                    <div className='icon primary large'><CalendarMonthIcon /></div>
                                                    <div className='text primary large'><strong>Average Age:</strong> {selectedFarm.currentSimulationRecord.herdProperties.age} years</div>
                                                </div>

                                            </Grid>
                                            <Grid item xs={isMobile ? 6 : 4}>
                                                <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                    <div className='icon primary large'><MonitorHeartIcon /></div>
                                                    <div className='text primary large'><strong>Health Status:</strong> {selectedFarm.currentSimulationRecord.herdProperties.healthStatus}</div>
                                                </div>

                                            </Grid>
                                            <Grid item xs={isMobile ? 6 : 4}>
                                                <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                    <div className='icon primary large'><YardIcon /></div>
                                                    <div className='text primary large'><strong>Feed Supplement:</strong> {selectedFarm.currentSimulationRecord.herdProperties.feedSupplement}</div>
                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Divider className='divider secondary small' />


                                        <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                            <div className='icon primary xlarge'><GiGrass /></div>
                                            <div className='text primary bold xlarge'>Forage</div>
                                        </div>

                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                    <div className='icon primary large'><LandscapeIcon /></div>
                                                    <div className='text primary large'><strong>Grassland Area:</strong> {selectedFarm.currentSimulationRecord.forageData.grasslandArea} hectares</div>
                                                </div>

                                            </Grid>
                                            <Grid item xs={6}>
                                                <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                    <div className='icon primary large'><GrassIcon /></div>
                                                    <div className='text primary large'><strong>Grass Height:</strong> {selectedFarm.currentSimulationRecord.forageData.grassHeight} cm</div>
                                                </div>

                                            </Grid>
                                        </Grid>

                                        <Divider className='divider secondary small' />




                                        <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                            <div className='icon primary xlarge'><GiWheat /></div>
                                            <div className='text primary bold xlarge'>Crop</div>
                                        </div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                    <div className='icon primary large'><FenceIcon /></div>
                                                    <div className='text primary large'><strong>Crop Area:</strong> {selectedFarm.currentSimulationRecord.cropData.cropArea} hectares</div>
                                                </div>
                                               
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div style={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                                    <div className='icon primary large'><GrassIcon /></div>
                                                    <div className='text primary large'><strong>Crop Type:</strong> {selectedFarm.currentSimulationRecord.cropData.cropType}</div>
                                                </div>
                                               
                                            </Grid>
                                        </Grid>
                                    </div>

                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDialog} className='button secondary'>
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        )}
                    </Box>
                </div>


                {farms.length ?
                    <>
                        <Divider className='divider primary small' />


                        <div className='card-container'>
                            <div className='text primary xlarge'>Manage Farms</div>
                            {farms.map((farm, index) => {
                                return (
                                    <Accordion
                                        sx={{
                                            border: "1px solid #bbb",
                                            boxShadow: `-10px 0px 3px 1px ${farm.color}`,
                                            margin: "25px !important",
                                            borderRadius: 5,

                                            '& .MuiAccordionSummary-root': {
                                                backgroundColor: selectedFarmId === farm.id ? "#bbbbbb61" : "inherit",
                                                borderRadius: 5
                                            },
                                            '& .MuiAccordionSummary-root:hover': {
                                                backgroundColor: "#bbbbbb8c"
                                            }

                                        }}
                                        expanded={selectedFarmId === farm.id}
                                        onChange={() => {
                                            onFarmSelect(farm.id)
                                        }}
                                        ref={(el) => (farmRefs.current[farm.id] = el)}
                                        key={farm.id}
                                    >
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                            <Typography>
                                                <TextField
                                                    label="Farm Name"
                                                    required
                                                    value={farm.name}
                                                    onChange={(e) => updateFarm(farm.id, { ...farm, name: e.target.value })}
                                                    variant="standard"
                                                    onClick={(e) => {
                                                        if (selectedFarmId === farm.id) {
                                                            e.stopPropagation()
                                                        }
                                                    }}
                                                />
                                            </Typography>
                                            <Typography>
                                                <TextField
                                                    label="Simulation Record Name"
                                                    value={farm.currentSimulationRecord.name}
                                                    onChange={(e) => updateFarm(farm.id, { ...farm, currentSimulationRecord: { ...farm.currentSimulationRecord, name: e.target.value } })}
                                                    variant="standard"
                                                    onClick={(e) => {
                                                        if (selectedFarmId === farm.id) {
                                                            e.stopPropagation()
                                                        }
                                                    }}
                                                />
                                            </Typography>
                                        </AccordionSummary>



                                        <AccordionDetails>



                                            <FarmInputForm farm={farm} updateFarm={updateFarm} />



                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                        </div>
                    </>
                    : ""}

                <Tooltip title="Save Changes">
                    <Fab
                        className='button primary rounded'
                        aria-label="save"
                        onClick={saveChanges}
                        disabled={!isFarmsDataChanged}
                        sx={{
                            position: 'fixed',
                            bottom: '20px',
                            right: '20px',
                            zIndex: 4,
                        }}
                        style={isMobile ? {
                            width: isMobile ? "7rem" : "auto",
                            height: isMobile ? "7rem" : "auto"
                        } : {}}
                    >
                        <SaveIcon style={isMobile ? { fontSize: "4rem" } : {}} />
                    </Fab>
                </Tooltip>

            </div >

        </>

    )
}