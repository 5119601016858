import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, TextField, Box, Alert, InputAdornment, Grid, useMediaQuery } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import { AlertContext } from "../Assets/Components/Alerter";
import SubjectIcon from '@mui/icons-material/Subject';
import MessageIcon from '@mui/icons-material/Message';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import SendIcon from '@mui/icons-material/Send';
import { FancyBackground } from "../Assets/Components/General";

export default function Contact() {
    const [name, setname] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setsubject] = useState("");
    const [body, setbody] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setisLoading] = useState(false)
    const { setAlert } = useContext(AlertContext)

    const isMobile = useMediaQuery('(max-width:1024px)');


    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    function sendMail() {
        setisLoading(true)
        axios.post(process.env.REACT_APP_BACKEND_API + "/contactUs", { name, email, subject, body }).then(() => {
            setAlert("We appreciate you reaching out to us. Your message has been received, and we will get back to you shortly.")
            setMessage()
        }).catch((err) => {
            const errorMsg = err.response.data.data


            setMessage(errorMsg)
        }).finally(() => {
            setisLoading(false)
        })
    }

    return (
        <div className="container empty">
            <FancyBackground image={require("../Assets/img/bg-layers/pt-bg-gal.jpg")} style={{ position: "absolute", left: 0, right: 0 }} title={"Get in Touch"} label={"We'd love to hear from you"} />
            <Grid className="card-container center" sx={{ width: "40% !important" }}>
                <div onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        sendMail()
                    }
                }}>

                    <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                        <img
                            src={require("../Assets/img/logo.png")}
                            alt="logo"
                            style={{ width: 90 }}
                            className="logo"
                        />
                    </Box>

                    <div className="text bold secondary center xxlarge title">Contact Us</div>
                    <div className="text bold secondary center large sub-title">We will reply you within 24 hours via email, thank you for contacting</div>



                    <Box component="form" noValidate sx={{ mt: 3, mb: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    className="input primary mb-medium"
                                    fullWidth
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setname(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <DriveFileRenameOutlineIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    className="input primary mb-medium"
                                    fullWidth
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EmailIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            variant="outlined"
                            className="input primary mb-medium"
                            fullWidth
                            label="Subject"
                            value={subject}
                            onChange={(e) => setsubject(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SubjectIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            variant="outlined"
                            className="input primary"
                            fullWidth
                            label="Message"
                            value={body}
                            onChange={(e) => setbody(e.target.value)}
                            multiline
                            rows={4}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <MessageIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />


                        {message && message !== "ok" && (
                            <Alert className="sub-title" severity="error" sx={{ mt: 2, mb: 2 }}>
                                {message}
                            </Alert>
                        )}
                    </Box>



                    <Button
                        disabled={isLoading}
                        fullWidth
                        variant="contained"
                        className="button primary"
                        onClick={sendMail}
                        endIcon={!isLoading ? <SendIcon /> : <CircularProgress size={!isMobile ? 25 : 50} color="inherit" />}>

                        {!isLoading && "Submit"}
                    </Button>

                </div>
            </Grid>
        </div>
    )
}