import { Card, CardHeader, CardContent, Divider } from "@mui/material";



export default function CardItems({ title, values }) {


    return (
        <Card className='card'>
            <CardHeader className='card-title' title={title}></CardHeader>
            <Divider />
            <CardContent className='card-content'>
                {values.map((value, index) => (
                    <div key={index}>
                        <div style={{ margin: 5, color: `hsl(${index * 100}, 100%, 30%)` }}>
                            {value}
                        </div>
                        {index < values.length - 1 ? <Divider /> : ""}
                    </div>
                ))}
            </CardContent>
        </Card>
    )
}