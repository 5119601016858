import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { FaCow, FaTemperatureHigh } from "react-icons/fa6";
import GrassIcon from '@mui/icons-material/Grass';
import { FarmRecordModel } from '../shared/InputModels';
import { breedIcons, cropIcons } from './General';

const FarmMarkerLabel = ({ farm = FarmRecordModel() }) => (
    <div style={{
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '12px',
        backgroundColor: '#333',
        border: '3px solid #fff',
        padding: '10px',
        width:"100%",
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)'
    }}>
        <div style={{
            backgroundColor: '#656565',
            borderRadius: '8px',
            padding: '4px',
            marginBottom: '6px',
            fontSize: '17px'
        }}>
            {farm.name}
        </div>
        <div style={{ marginTop: '0px' }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: '#fff'
            }}>
                {farm.currentSimulationRecord.herdProperties.herdSize ?
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '0px' }}>
                        <div className='icon primary2 center large mr-small'>{breedIcons[farm.currentSimulationRecord.herdProperties.breed]}</div>
                        <div className='icon primary2 center large'>{farm.currentSimulationRecord.herdProperties.herdSize}</div>
                    </div>
                    :
                    ""
                }


                {farm.currentSimulationRecord.cropData.cropArea ?
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                        <div className='icon primary2 center large mr-small'>{cropIcons[farm.currentSimulationRecord.cropData.cropType]}</div>
                        <div className='icon primary2 center large'>{farm.currentSimulationRecord.cropData.cropType}</div>
                    </div>
                    :
                    ""
                }

                <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                    <div className='icon primary2 center large mr-small'><FaTemperatureHigh style={{ color: '#ffcc00' }} /></div>
                    <div className='icon primary2 center large'>{farm.weather.temperature}°C</div>
                </div>
            </div>
        </div>
    </div>

);

export const getFarmMarkerLabel = (farm) => {
    return ReactDOMServer.renderToStaticMarkup(<FarmMarkerLabel farm={farm} />);
};
