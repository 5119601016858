import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Divider, Paper, Box, useMediaQuery } from '@mui/material';
import { fetchWeatherData, getFarmLastSimulationRecord, getFarmsData, getRandomGreeting, getUserData, getUserPosition } from '../../Assets/Utils';
import { userStatisticsModel, WeatherForcastModel, weatherModel } from '../../Assets/shared/InputModels';
import { PiFarmFill } from "react-icons/pi";
import Loader from '../../Assets/Components/Loader';
import { Doughnut, PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, RadialLinearScale } from 'chart.js';
import { breedIcons, CardIconItem, ClockCard, cropIcons, getWeatherBackground, WallpaperSlideshow } from '../../Assets/Components/General';
import { PiCowFill } from "react-icons/pi";
import axios from 'axios';
import { AlertContext } from '../../Assets/Components/Alerter';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Clock from '../../Assets/Components/Clock';
import Calendar from '../../Assets/Components/Calendar';
import BackgroundSlider from 'react-background-slider';




ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    RadialLinearScale,
    //ChartDataLabels
);


const Overview = () => {
    const [farms, setFarms] = useState([]);
    const [isLoading, setisLoading] = useState(true)
    const [userStatistics, setuserStatistics] = useState(userStatisticsModel())
    const { setAlert } = useContext(AlertContext)
    const userData = getUserData()
    const [greetingMsg, setGreetingMsg] = useState(getRandomGreeting(userData.first_name))
    const [time, setTime] = useState(new Date());

    const isMobile = useMediaQuery('(max-width:1024px)');



    useEffect(() => {
        init()

        const intervalId = setInterval(() => setTime(new Date()), 1000);
        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [])


    const formatDate = (date) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options); // Localized date
    };

    async function init() {
        getUserPosition(setAlert, async (userPos) => {

            try {
                const response = await axios.get(process.env.REACT_APP_BACKEND_API + "/getUserStatistics",)

                setuserStatistics(response.data.data)
            } catch (error) {
            }




            const farms = await getFarmsData()



            setFarms(farms)
            setisLoading(false)
        })
    }



    const breedDistribution = farms.reduce((acc, farm) => {
        if (!getFarmLastSimulationRecord(farm).herdProperties.herdSize) {
            return acc
        }
        const breed = getFarmLastSimulationRecord(farm).herdProperties.breed
        acc[breed] = (acc[breed] || 0) + getFarmLastSimulationRecord(farm).herdProperties.herdSize
        return acc
    }, {})




    const cropDistribution = farms.reduce((acc, farm) => {
        if (!getFarmLastSimulationRecord(farm).cropData.cropArea) {
            return acc
        }
        const crop = getFarmLastSimulationRecord(farm).cropData.cropType
        acc[crop] = (acc[crop] || 0) + 1
        return acc
    }, {})




    return (
        <Loader isLoading={isLoading}>
            <div className='container'>
                <WallpaperSlideshow />



                <div className='mb-medium' style={isMobile ? { display: "flex", flexDirection: "column", gap: "2rem" } : { display: "flex", alignItems: "flex-start", justifyContent: "space-evenly" }}>
                    <div className='card-container'>
                        <div className='text secondary xlarge center mb-medium' style={isMobile ? { fontSize: "2.5rem" } : {}}>{greetingMsg}</div>

                        <Clock />
                    </div>
                    <div className='card-container' >
                        <Calendar />
                    </div>
                </div>





                <Grid container spacing={5} className='mb-large'>
                    <Grid item xs={isMobile ? 6 : 3}>
                        <CardIconItem label={"Total Farms"} value={userStatistics.countFarms}
                            labelStyle={isMobile ? { fontSize: "3rem" } : {}}
                            valueStyle={isMobile ? { fontSize: "3rem" } : {}}
                            icon={<PiFarmFill style={isMobile ? { fontSize: "4rem" } : {}} />}
                            backgroundColor={"#206a3a"} />
                    </Grid>


                    <Grid item xs={isMobile ? 6 : 3}>
                        <CardIconItem label={"Total Livestock"} value={userStatistics.countLiveStock}
                            labelStyle={isMobile ? { fontSize: "3rem" } : {}}
                            valueStyle={isMobile ? { fontSize: "3rem" } : {}}
                            icon={<PiCowFill style={isMobile ? { fontSize: "4rem" } : {}} />}
                            backgroundColor={"#0081ff"} />
                    </Grid>

                    <Grid item xs={isMobile ? 6 : 3}>
                        <CardIconItem label={"Total Team Participation"} value={userStatistics.countTeams}
                            labelStyle={isMobile ? { fontSize: "3rem" } : {}}
                            valueStyle={isMobile ? { fontSize: "3rem" } : {}}
                            icon={<GroupsIcon style={isMobile ? { fontSize: "4rem" } : {}} />}
                            backgroundColor={"#18548e"} />
                    </Grid>

                    <Grid item xs={isMobile ? 6 : 3}>
                        <CardIconItem label={"Total Assigned Tasks"} value={userStatistics.countTasks}
                            labelStyle={isMobile ? { fontSize: "3rem" } : {}}
                            valueStyle={isMobile ? { fontSize: "3rem" } : {}}
                            icon={<AssignmentIcon style={isMobile ? { fontSize: "4rem" } : {}} />}
                            backgroundColor={"#844022"} />
                    </Grid>
                </Grid>




                {(Object.keys(cropDistribution).length || Object.keys(breedDistribution).length) ?
                    <Grid container spacing={5} style={{ width: "100%", marginLeft: "auto", marginRight: "auto", justifyContent: "center" }}>
                        {Object.keys(cropDistribution).length ?
                            <Grid item xs={6}>
                                <div className='card-container'>
                                    <div className='text primary xlarge center' style={isMobile ? { fontSize: "3rem" } : {}}>Crop Distribution</div>
                                    <Grid item xs={12} sx={{ height: "30vh" }}>
                                        <CropChart isMobile={isMobile} cropDistribution={cropDistribution} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 16 }}>
                                            {Object.keys(cropDistribution).map((crop) => (
                                                <div key={crop}>
                                                    <div className='icon primary center large'>{cropIcons[crop]}</div>
                                                    <div className='text secondary center xlarge' style={isMobile ? { fontSize: "3rem" } : {}}>{crop}</div>
                                                    <div className='text primary center xlarge' style={isMobile ? { fontSize: "3rem" } : {}}>{cropDistribution[crop]}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </Grid>
                                </div>
                            </Grid>
                            :
                            ""
                        }

                        {Object.keys(breedDistribution).length ?
                            <Grid item xs={6}>
                                <div className='card-container'>
                                    <div className='text primary xlarge center' style={isMobile ? { fontSize: "3rem" } : {}}>Herd Breed Distribution</div>
                                    <Grid item xs={12} sx={{ height: "30vh" }}>
                                        <BreedChart isMobile={isMobile} breedDistribution={breedDistribution} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 16 }}>
                                            {Object.keys(breedDistribution).map((breed) => (
                                                <div key={breed}>
                                                    <div className='icon primary center large'>{breedIcons[breed]}</div>
                                                    <div className='text secondary center xlarge' style={isMobile ? { fontSize: "3rem" } : {}}>{breed}</div>
                                                    <div className='text primary center xlarge' style={isMobile ? { fontSize: "3rem" } : {}}>{breedDistribution[breed]}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </Grid>
                                </div>
                            </Grid>
                            :
                            ""
                        }
                    </Grid>
                    :
                    ""
                }



            </div >
        </Loader>
    );
};

export default Overview;



function chartOptions(isMobile, title = "") {
    return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    font: {
                        size: isMobile ? 40 : 20,
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const label = tooltipItem.label || '';
                        const value = tooltipItem.raw || 0;
                        return `${label}: ${value} ${title && (title + (value > 1 ? "s" : ""))}`;
                    },
                },
                bodyFont: {
                    size: isMobile ? 40 : 20,
                },
            },
        },
    }
};

const CropChart = ({ isMobile, cropDistribution }) => {
    const data = {
        labels: Object.keys(cropDistribution),
        datasets: [
            {
                label: 'Number of Farms',
                data: Object.values(cropDistribution),
                backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726', '#AB47BC'],
                hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D', '#BA68C8'],
            },
        ],
    };



    return <Doughnut data={data} options={chartOptions(isMobile, "Farm")} />;
};





const BreedChart = ({ isMobile, breedDistribution }) => {
    const data = {
        labels: Object.keys(breedDistribution),
        datasets: [
            {
                label: 'Number of Cows',
                data: Object.values(breedDistribution),
                backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726', '#AB47BC'],
                hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D', '#BA68C8'],
            },
        ],
    };



    return <PolarArea data={data} options={chartOptions(isMobile, "")} />;
};
