import { useEffect, useState } from "react";




export default function Loader({ isLoading, children }) {
    const [isFadingOut, setIsFadingOut] = useState(false);

    useEffect(() => {


        if (isLoading) {
            document.body.style.overflow = "hidden";
            setIsFadingOut(false);
        } else {
            setIsFadingOut(true);
            document.body.style.overflow = "auto";

        }
    }, [isLoading]);

    return (
        <>
            <div className={`loader ${isLoading ? "fade-in" : "fade-out"}`}>
                <img alt="loading" src={require("../img/loader2.gif")} />
            </div>
            {!isLoading && children}
        </>
    )

}