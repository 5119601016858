export const healthStatuses = ['Healthy', 'Sick', 'Recovering'];
export const feedSupplements = ['None', 'Grain', 'Protein Supplement', 'Vitamin Supplement'];
export const breeds = ['Holstein', 'Jersey', 'Guernsey', 'Ayrshire'];
export const cropTypes = ["Wheat", "Barley", "Field peas", "Lupins"]





export const coordinatesModel = (
    lat = 40,
    lon = 40
) => {
    return {
        lat,
        lon
    };
};


export const weatherModel = (
    timestamp = 0,
    temperature = 20.0,
    minTemp = 10.0,
    maxTemp = 50.0,
    windSpeed = 1.0,
    humidity = 20.0,
    precipitation = 5.0,
    radiation = 15.0,
    description = "rain",
    country = "TN"
) => {
    return {
        timestamp,
        temperature,
        minTemp,
        maxTemp,
        windSpeed,
        humidity,
        precipitation,
        radiation,
        description,
        country
    }
}








export const herdModel = (
    breed = breeds[0],
    weight = 450,
    age = 3,
    healthStatus = healthStatuses[0],
    feedSupplement = feedSupplements[0],
    herdSize = 0,
    dailyMilkProduction = 25.0,
    dailyFeedNeeds = 10.0
) => {

    return {
        breed,
        weight,
        age,
        healthStatus,
        feedSupplement,
        herdSize,

        dailyMilkProduction,
        dailyFeedNeeds
    }
}




export const forageModel = (
    grasslandArea = 0,
    grassHeight = 8.0
) => {

    return {
        grasslandArea,
        grassHeight
    }
}


export const cropModel = (
    cropArea = 0,
    cropType = cropTypes[0],
    headsPerM2 = 200
) => {

    return {
        cropArea,
        cropType,
        headsPerM2
    }
}





export const WeatherForcastModel = (
    hourlyForecastList = [],
    dailyForecastList = []
) => {
    return {
        hourlyForecastList,
        dailyForecastList
    }
}



// each farm has it's own record saved in DB
export const FarmRecordModel = (
    id = 0,
    name = "Farm 1",
    layer = null,    // front-end only
    marker = null,   // front-end only
    geoLayer = {},
    weather = weatherModel(), // front-end only
    color = "#bbbbbb",
    simulationRecords = [],
    currentSimulationRecord = simulationRecordModel() // front-end only
) => {
    return {
        id,
        name,
        layer,
        marker,
        geoLayer,
        weather,
        color,
        simulationRecords,
        currentSimulationRecord
    }
}

// simulation record represents snapshot of a farm state/values at a given time
// each change made to a farm should be saved as unique simulation record
// simulation records used for decision making and calculations at a given time
// example: farm grass/arable area may increase or decrease by the user, so the farm output calculations vary based on farm state/values at a given time
// last simulation record represents current farm state/values
export const simulationRecordModel = (
    id = 0,
    farm_id = 0,
    name = `Season 1`,
    date = new Date(),
    bounds = [coordinatesModel(40, 40), coordinatesModel(41, 41), coordinatesModel(42, 42), coordinatesModel(40, 40)],   // polygon bounds of the farm
    centerCoordinates = coordinatesModel(), // center coordinates of the farm
    herdProperties = herdModel(),
    forageData = forageModel(),
    cropData = cropModel(),
) => {
    return {
        id,
        farm_id,
        name,
        date,
        bounds,
        centerCoordinates,
        herdProperties,
        forageData,
        cropData
    }
}



export const farmResultModel = (
    simulationRecords = [simulationRecordModel()],
    farm_id = 0,
    dates = [],
    dailyWeather = [],
    dailyForageProduction = [],
    dailyMilkYield = [],
    dailyFeedNeeds = [],
    dailyForageSurplus = [],
    meanForageProduction = 0,
    meanFeedNeeds = 0,
    meanForageSurplus = 0,
    meanMilkYield = 0,
    cropYield = 0,
    recommendation = ""
) => {
    return {
        simulationRecords,
        farm_id,
        dates,
        dailyWeather,
        dailyForageProduction,
        dailyMilkYield,
        dailyFeedNeeds,
        dailyForageSurplus,
        meanForageProduction,
        meanFeedNeeds,
        meanForageSurplus,
        meanMilkYield,
        cropYield,
        recommendation
    }
}




export const statisticsModel = () => {
    return {
        countUsers: 0,
        countFarms: 0,
        countSimulations: 0,
        countTeams:0,
        countNationalities: { "TN": 0 }
    }
}



export const userStatisticsModel = () => {
    return {
        countFarms: 0,
        countLiveStock: 0,
        countTeams: 0,
        countTasks: 0,
    }
}






export const NotificationCodes = [
    "unassignTask",
    "assignTask",
    "unassignTeam",
    "assignTeam",
    "leaveTeam",
    "newEvent",
    "updateEvent"
]