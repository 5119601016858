import React, { useEffect, useState, useMemo } from 'react';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, TimeScale, ArcElement } from 'chart.js';
import { Typography, Grid, Box, Select, MenuItem, FormControl, InputLabel, Divider, Button, FormControlLabel, Switch, useMediaQuery } from '@mui/material';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import { List, ListItem, ListItemText } from '@mui/material';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import axios from 'axios';
import { getFarmLastSimulationRecord, getFarmsData } from '../Utils';
import CardItems from './CardItems';
import Loader from './Loader';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CheckCircle, Warning, Info } from '@mui/icons-material'; // Import icons as needed
import { IconTitle } from './General';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined'; // Weather forecast icon
import GrassOutlinedIcon from '@mui/icons-material/GrassOutlined'; // Forage forecast icon
import LocalDrinkOutlinedIcon from '@mui/icons-material/LocalDrinkOutlined'; // Milk yield forecast icon
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined'; // Feed needs forecast icon
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined'; // Comparison icon
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'; // Forage surplus icon



ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    zoomPlugin,
    annotationPlugin
);

export default function SimulationResults({ simulationRecords, farms, small }) {
    const forcastPeriods = Array.from({ length: 5 }, (_, i) => i)
    const [forcastPeriod, setforcastPeriod] = useState(forcastPeriods[0]);
    const [simulationResults, setSimulationResults] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const isMobile = useMediaQuery('(max-width:1024px)');


    const handleSimulate = async () => {
        try {
            setisLoading(true)

            const response = await axios.post(process.env.REACT_APP_BACKEND_API + "/simulateFarmsSimulations", {
                simulationRecords: simulationRecords.map((record) => {
                    return { id: record.id, farm_id: record.farm_id }
                }),
                forcastPeriod
            })



            setSimulationResults(response.data.data)
        } catch (error) {
            //alert(error.response.data.data)
        } finally {
            setisLoading(false)
        }
    }




    useEffect(() => {
        if (simulationRecords.length && farms.length) {
            handleSimulate()
        }
        if (!simulationRecords.length && simulationResults.length) {
            setSimulationResults([])
        }
    }, [forcastPeriod, simulationRecords]);


    function getFullSimulationName(simulation) {
        return `${farms.find(farm => farm.id === simulation.farm_id).name} - ${simulation.name}`
    }

    function getFarmName(farm_id) {
        return `${farms.find(farm => farm.id === farm_id).name}`
    }

    const processData = (results, key) => {
        return results.map((result, index) => ({
            label: key.replace(/([a-z])([A-Z])/g, '$1 $2') + " (" + farms.find(farm => farm.id === result.farm_id).name + ")",
            data: result.simulationRecords.map((simRecord, i) => {
                return {
                    x: new Date(result.dates[i]),
                    y: result[key][i].toFixed(2),
                    label: key.replace(/([a-z])([A-Z])/g, '$1 $2') + ": " + getFullSimulationName(simRecord)
                };
            }),

            borderColor: `hsl(${index * 80}, 100%, 50%)`,
            backgroundColor: `hsla(${index * 80}, 100%, 50%, 0.6)`,
            borderWidth: 2,
            barThickness: 20,
            minBarLength: 10,
            pointRadius: 8,
            pointHoverRadius: 15,
            tension: 0.4,
            fill: true,
        }));
    };


    const processWeatherData = (results, fieldAccess, fieldLabels) => {
        return fieldAccess.map((accessPath, index) => {
            const label = fieldLabels[index];

            return {
                label: label,
                data: results.flatMap((result) => result.dailyWeather.map((weather, i) => {
                    const fieldParts = accessPath.split('.');
                    let value = weather;

                    for (const part of fieldParts) {
                        if (value === undefined) {
                            console.error(`Value for accessPath '${accessPath}' is undefined.`);
                            return null;
                        }
                        value = value[part];
                    }

                    if (value === undefined) {
                        console.error(`Value for accessPath '${accessPath}' is undefined.`);
                        return null;
                    }

                    return {
                        x: new Date(result.dates[i]),
                        y: parseFloat(value).toFixed(2),
                        label: `${label}`
                    };
                }).filter(dataPoint => dataPoint !== null)),
                borderColor: `hsl(${index * 80}, 100%, 50%)`,
                backgroundColor: `hsla(${index * 80}, 100%, 50%, 0.6)`,
                borderWidth: 2,
                pointRadius: 8,
                pointHoverRadius: 15,
            };
        });
    };





    const processedDataForage = useMemo(() => processData(simulationResults, 'dailyForageProduction'), [simulationResults]);
    const processedDataMilkYield = useMemo(() => processData(simulationResults, 'dailyMilkYield'), [simulationResults]);
    const processedDataFeedNeeds = useMemo(() => processData(simulationResults, 'dailyFeedNeeds'), [simulationResults]);
    const processedDataComparison = useMemo(() => [
        ...processData(simulationResults, 'dailyForageProduction'),
        ...processData(simulationResults, 'dailyFeedNeeds')
    ], [simulationResults]);
    const processedDataSurplus = useMemo(() => processData(simulationResults, 'dailyForageSurplus'), [simulationResults]);

    const dataForage = useMemo(() => ({
        datasets: processedDataForage,
    }), [processedDataForage]);

    const dataMilk = useMemo(() => ({
        datasets: processedDataMilkYield,
    }), [processedDataMilkYield]);

    const dataFeedNeeds = useMemo(() => ({
        datasets: processedDataFeedNeeds,
    }), [processedDataFeedNeeds]);

    const dataComparison = useMemo(() => ({
        datasets: processedDataComparison,
    }), [processedDataComparison]);

    const dataSurplus = useMemo(() => ({
        datasets: processedDataSurplus,
    }), [processedDataSurplus]);


    const chartOptions = (title, xLabel, xUnit, yLabel, yUnits = []) => {
        const today = new Date();

        const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);

        const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);

        return {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            day: 'MMM d, h:mm a'
                        },
                        tooltipFormat: 'MMM d, yyyy, h:mm a'
                    },
                    title: {
                        display: true,
                        text: xLabel,
                        color: '#4a4a4a',
                        font: {
                            family: 'Arial',
                            size: 14,
                            weight: 'bold',
                        }
                    },
                    ticks: {
                        callback: xUnit && function (value) {
                            return value + " " + xUnit
                        },
                        maxRotation: 45,
                        minRotation: 0,
                        color: '#4a4a4a',
                        font: {
                            family: 'Arial',
                            size: 12,
                        }
                    },
                },
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: yLabel,
                        color: '#4a4a4a',
                        font: {
                            family: 'Arial',
                            size: 14,
                            weight: 'bold',
                        }
                    },
                    ticks: {
                        callback: function (value, index, values) {
                            if (yUnits.length > 1) {
                                const unitIndex = index % yUnits.length;
                                return value + " " + yUnits[unitIndex];
                            }
                            return yUnits[0] ? value + " " + yUnits[0] : value;
                        },
                        color: '#4a4a4a',
                        font: {
                            family: 'Arial',
                            size: 12,
                        }
                    }
                },
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        color: '#4a4a4a',
                        font: {
                            size: isMobile ? 30 : 15,
                        }
                    }
                },
                title: {
                    display: true,
                    text: title,
                    color: '#4a4a4a',
                    font: {
                        family: 'Arial',
                        size: 16,
                        weight: 'bold',
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            const label = context.raw.label || '';
                            const value = context.raw.y || context.parsed.y;
                            const unit = yUnits.length > 1 ? yUnits[context.datasetIndex] : yUnits[0];
                            return `${label}: ${value} ${unit}`;
                        }
                    },
                    bodyFont: {
                        size: isMobile ? 30 : 15,
                    },
                },
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'x',
                    },
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        mode: 'x',
                    },
                },
                annotation: {
                    annotations: {
                        currentDayHighlight: {
                            type: 'box',
                            xMin: startOfDay,
                            xMax: endOfDay,
                            backgroundColor: 'rgba(74, 144, 226, 0.1)',
                            borderColor: 'rgba(74, 144, 226, 1.0)',
                            borderWidth: 2,
                        }
                    }
                }
            }
        };
    };





    const CropYieldChart = () => {
        const labels = simulationResults.map(sim => getFarmName(sim.farm_id));
        const data = simulationResults.map(sim => sim.cropYield);

        const chartData = {
            labels,
            datasets: [
                {
                    label: 'Estimated Crop Yield',
                    data,
                    backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726', '#AB47BC'],
                    hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D', '#BA68C8'],
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        font: {
                            size: isMobile ? 30 : 15,
                        },
                    },
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            const label = tooltipItem.label || '';
                            const value = tooltipItem.raw || 0;
                            const cropType = getFarmLastSimulationRecord(farms.find(farm => farm.id == tooltipItem.dataIndex)).cropData.cropType;
                            return `${label}: ${value.toFixed(2)} Kg of ${cropType}`;
                        },
                    },
                    bodyFont: {
                        size: isMobile ? 30 : 15,
                    },
                },
            },
        };

        return (
            <Doughnut style={{ marginBottom: "50px" }} data={chartData} options={options} />
        );
    };



    const [showWeather, setShowWeather] = useState(true);
    const [showForage, setShowForage] = useState(true);
    const [showMilkYield, setShowMilkYield] = useState(true);
    const [showFeedNeeds, setShowFeedNeeds] = useState(true);
    const [showComparison, setShowComparison] = useState(true);
    const [showSurplus, setShowSurplus] = useState(true);

    return (
        <Loader isLoading={isLoading}>
            {!simulationResults.length ?
                <IconTitle title={"Please select or run a simulation to view the results"} icon={require("../img/farm simulation.gif")} />
                :
                <div className='simulation-result'>
                    <Typography variant="h5" gutterBottom>
                        Simulation Results
                    </Typography>




                    <Grid container spacing={2}>
                        <Grid item xs={isMobile ? 12 : 8}>
                            <div className="card-container" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                {/* Forecast Period Select */}
                                <FormControl variant="outlined" style={{ width: "30%" }}>
                                    <InputLabel>Forecast Period</InputLabel>
                                    <Select
                                        value={forcastPeriod}
                                        onChange={(e) => setforcastPeriod(e.target.value)}
                                        label="Forecast Period"
                                    >
                                        {forcastPeriods.map((period, index) => (
                                            <MenuItem key={index} value={period}>
                                                {period === 0
                                                    ? "Until Today"
                                                    : period === 1
                                                        ? "Until Tomorrow"
                                                        : `After ${period} days`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* Toggles for Weather, Forage, Milk Yield, Feed Needs, Comparison, and Surplus */}
                                <Box mt={2} display="flex" flexDirection="column" alignItems="flex-start" gap={2}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={showWeather}
                                                onChange={() => setShowWeather(!showWeather)}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Box display="flex" alignItems="center">
                                                <CloudOutlinedIcon style={isMobile ? { fontSize: "4rem" } : {}} />
                                                <div className='text primary bold large'>Forecast Weather</div>
                                            </Box>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={showForage}
                                                onChange={() => setShowForage(!showForage)}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Box display="flex" alignItems="center">
                                                <GrassOutlinedIcon style={isMobile ? { fontSize: "4rem" } : {}} />
                                                <div className='text primary bold large'>Forecast Forage</div>
                                            </Box>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={showFeedNeeds}
                                                onChange={() => setShowFeedNeeds(!showFeedNeeds)}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Box display="flex" alignItems="center">
                                                <RestaurantMenuOutlinedIcon style={isMobile ? { fontSize: "4rem" } : {}} />
                                                <div className='text primary bold large'>Forecast Feed Needs</div>
                                            </Box>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={showMilkYield}
                                                onChange={() => setShowMilkYield(!showMilkYield)}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Box display="flex" alignItems="center">
                                                <LocalDrinkOutlinedIcon style={isMobile ? { fontSize: "4rem" } : {}} />
                                                <div className='text primary bold large'>Forecast Milk Yield</div>
                                            </Box>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={showComparison}
                                                onChange={() => setShowComparison(!showComparison)}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Box display="flex" alignItems="center">
                                                <CompareArrowsOutlinedIcon style={isMobile ? { fontSize: "4rem" } : {}} />
                                                <div className='text primary bold large'>Comparison of Forage Production and Feed Needs</div>

                                            </Box>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={showSurplus}
                                                onChange={() => setShowSurplus(!showSurplus)}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Box display="flex" alignItems="center">
                                                <AddCircleOutlineOutlinedIcon style={isMobile ? { fontSize: "4rem" } : {}} />
                                                <div className='text primary bold large'>Forage Surplus</div>
                                            </Box>
                                        }
                                    />
                                </Box>
                            </div>


                            <Grid container spacing={2} mt={1}>



                                {showWeather &&
                                    <Grid item xs={12}>
                                        <div className='card-container'>

                                            <div className='text primary bold xlarge'>Weather information</div>
                                            <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                                                {simulationResults.map((simResult, index) => {
                                                    const fieldAccess = ['temperature', 'minTemp', 'maxTemp', 'windSpeed', 'humidity', 'precipitation', 'radiation'];
                                                    const fieldLabels = ['Temperature', 'Min Temperature', 'Max Temperature', 'Wind Speed', 'Humidity', 'Precipitation', 'Radiation'];
                                                    const farmResults = simulationResults.filter(result => result.farm_id === simResult.farm_id);
                                                    const processedWeatherData = processWeatherData(farmResults, fieldAccess, fieldLabels);
                                                    const dataWeather = { datasets: processedWeatherData };

                                                    return (
                                                        <Grid item xs={6} key={index} sx={{ height: "50vh" }}>
                                                            <Line
                                                                data={dataWeather}
                                                                options={chartOptions(`${farms.find(farm => farm.id === simResult.farm_id).name}`, "Date", "", "", ["°C", "°C", "°C", "km/h", "%", "mm", "MJ/m²"])}
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </div>
                                    </Grid>
                                }





                                {showForage &&
                                    <Grid item xs={12} >
                                        <div className='card-container' style={{ height: "50vh" }}>

                                            <div className='text primary bold xlarge'>Forage Production Over Time</div>
                                            <Line style={{ marginBottom: "50px" }} data={dataForage} options={chartOptions("Forage Production Over Time", "Date", "", "Production", ["Kg"])} />
                                        </div>
                                    </Grid>
                                }


                                {showFeedNeeds &&
                                    <Grid item xs={12} >
                                        <div className='card-container' style={{ height: "50vh" }}>

                                            <div className='text primary bold xlarge'>Feed Needs Over Time</div>
                                            <Line style={{ marginBottom: "50px" }} data={dataFeedNeeds} options={chartOptions("Feed Needs Over Time", "Date", "", "Needs", ["Kg"])} />
                                        </div>
                                    </Grid>

                                }


                                {showMilkYield &&
                                    <Grid item xs={12} >
                                        <div className='card-container' style={{ height: "50vh" }}>

                                            <div className='text primary bold xlarge'>Milk Yield Over Time</div>
                                            <Bar style={{ marginBottom: "50px" }} data={dataMilk} options={chartOptions("Milk Yield Over Time", "Date", "", "Yield", ["L"])} />
                                        </div>
                                    </Grid>
                                }


                                <Grid item xs={12} mt={1}>
                                    <div className='cards'>
                                        {showForage && <CardItems title={"Mean Forage Production (Daily)"} values={simulationResults.map(sim => `${getFarmName(sim.farm_id)}: ${sim.meanForageProduction.toFixed(2)} Kg`)} />}
                                        {showFeedNeeds && <CardItems title={"Mean Feed Needs (Daily)"} values={simulationResults.map(sim => `${getFarmName(sim.farm_id)}: ${sim.meanFeedNeeds.toFixed(2)} Kg`)} />}
                                        {showMilkYield && <CardItems title={"Mean Milk Yield (Daily)"} values={simulationResults.map(sim => `${getFarmName(sim.farm_id)}: ${sim.meanMilkYield.toFixed(2)} L`)} />}
                                    </div>
                                </Grid>






                                {showComparison &&
                                    <Grid item xs={12} >
                                        <div className='card-container' style={{ height: "50vh" }}>
                                            <Box mb={2}>
                                                <div className='text primary bold xlarge'>Comparison of Forage Production and Feed Needs Over Time</div>
                                            </Box>
                                            <Bar style={{ marginBottom: "50px" }} data={dataComparison} options={chartOptions("Comparison of Forage Production and Feed Needs Over Time", "Date", "", "Production Vs Needs", ["Kg"])} />
                                        </div>
                                    </Grid>
                                }



                                {showSurplus &&
                                    <Grid item xs={12}>
                                        <div className='card-container' style={{ height: "50vh" }}>

                                            <div className='text primary bold xlarge'>Forage Surplus Over Time</div>
                                            <Bar style={{ marginBottom: "50px" }} data={dataSurplus} options={chartOptions("Forage Surplus Over Time", "Date", "", "Surplus", ["Kg"])} />
                                        </div>
                                    </Grid>
                                }



                                <Grid item xs={12} mt={1}>
                                    <div className='cards'>
                                        {showSurplus && <CardItems title={"Mean Forage Surplus (Daily)"} values={simulationResults.map(sim => `${getFarmName(sim.farm_id)}: ${sim.meanForageSurplus.toFixed(2)} Kg`)} />}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>




                        <Grid item xs={isMobile ? 12 : 4}>
                            <Grid item xs={12}>
                                {simulationResults.map(sim => sim.cropYield ? true : false).includes(true) ?
                                    <Grid item xs={12}>
                                        <div className='card-container' style={{ height: "50vh" }}>

                                            <Box mb={2}>
                                                <div className='text primary bold xlarge'>Estimated Crop Yield</div>
                                            </Box>

                                            <CropYieldChart />
                                        </div>
                                    </Grid>
                                    :
                                    <Grid item xs={12} mt={5}>
                                        <div className='cards'>
                                            <CardItems title={"Estimated Crop Yield"} values={simulationResults.map(sim => `${getFarmName(sim.farm_id)}: ${sim.cropYield.toFixed(2)} Kg of ${getFarmLastSimulationRecord(farms.find(farm => farm.id == sim.farm_id)).cropData.cropType}`)} />
                                        </div>
                                    </Grid>
                                }

                                <div className='card-container mt-medium'>

                                    <div className='text primary bold xlarge'>Recommendations</div>
                                    <List className='recommendation-list'>
                                        {simulationResults.map((sim, index) => (
                                            <div key={index}>
                                                <ListItem className='recommendation-item'>
                                                    <ListItemText
                                                        primary={
                                                            <span style={{ color: `hsl(${index * 100}, 100%, 30%)` }}>
                                                                {"● " + getFarmName(sim.farm_id) + ":"}
                                                            </span>
                                                        }
                                                        secondary={
                                                            <Box>
                                                                {sim.recommendation.map((rec, idx) => (
                                                                    <Button
                                                                        key={idx}
                                                                        variant="contained"
                                                                        startIcon={
                                                                            rec.status === "info" ? (
                                                                                <Info color="primary" />
                                                                            ) : rec.status === "success" ? (
                                                                                <CheckCircle color="success" />
                                                                            ) : (
                                                                                <Warning color="warning" />
                                                                            )
                                                                        }
                                                                        style={{
                                                                            backgroundColor: "#f0f0f0",
                                                                            borderRadius: "12px",
                                                                            padding: "10px 16px",
                                                                            marginBottom: "10px",
                                                                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.15)",
                                                                            color: "#bbb",
                                                                            fontWeight: "bold",
                                                                            transition: "transform 0.3s ease-in-out",
                                                                            justifyContent: "flex-start",
                                                                            textAlign: "left",
                                                                            width: "100%"
                                                                        }}
                                                                        onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.02)")}
                                                                        onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                                                    >
                                                                        <div className='text primary bold medium'>{rec.message}</div>
                                                                    </Button>
                                                                ))}
                                                            </Box>

                                                        }
                                                        primaryTypographyProps={{ component: 'span', fontWeight: 'bold' }}
                                                        secondaryTypographyProps={{ fontWeight: "bold" }}
                                                    />
                                                </ListItem>
                                                {index < simulationResults.length - 1 && <Divider className="divider primary small" />}
                                            </div>
                                        ))}
                                    </List>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            }
        </Loader>
    );
}

